import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "list"]

  click(e) {
    if (this.listTarget.classList.contains('hidden')) {
      this.appendList(e)
    } else {
      this.removeList()
    }    
  }

  appendList(e) {
    this.listTarget.classList.remove("hidden")

    // prevent fetching providers if already fetched
    if (this.listTarget.children.length === 1) {
      let url = e.target.dataset.url

      fetch(url, {
        method: 'GET',
        credentials: 'include',
        dataType: 'script',
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token"),
          "Content-Type": "application/json"
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          data.forEach(item => {
            this.add(e, { title: `${item.first_name} ${item.last_name}`, url: `/providers/${item.id}`})
          });
        } else {
          this.add(e, {title: "No Providers found", url: "#"})
        }
      });
    }
    
  }

  removeList() {
    this.listTarget.classList.add("hidden")

  }

  add(event, item) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/title/g, item.title).replace(/#/g, item.url)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}