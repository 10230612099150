import {mapManager} from "./map_manager";
import selectionManager from "./selection_manager";

let directionsRenderer = null;
const directionsCache = new Map();

const DIRECTIONS_BUTTON_ID = "find-directions";
let DEFAULT_BUTTON_HTML;

/**
 * Initializes the directions button within the application.
 *
 * This function is a key part of setting up the user interface for direction finding. It performs three main actions:
 * 1. Attaches input event listeners to the search fields for members and providers. These listeners are responsible for
 *    resetting the directions button whenever the user starts a new search, ensuring the UI remains consistent and ready
 *    for a new directions calculation.
 * 2. Adds a click event listener to the directions button itself. This listener handles the process of disabling the button,
 *    changing its text to indicate calculation is in progress, attempting to find directions between the selected member and
 *    provider, displaying the calculated travel time, and finally, re-enabling the button.
 * 3. Stores the initial HTML content of the directions button. This is used to reset the button's state after a search is
 *    performed or if an error occurs during the direction finding process.
 */
export const initializeDirectionsButton = () => {
    addInputEventListeners();
    addDirectionsButtonEventListener();
    DEFAULT_BUTTON_HTML = document.getElementById(DIRECTIONS_BUTTON_ID).innerHTML;
};

/**
 * Attaches input event listeners to search fields.
 *
 * This function is responsible for adding input event listeners to both the member and provider search fields.
 * When an input event is triggered in either field, it calls the `resetDirectionsButton` function to reset the
 * directions button to its original state. This ensures that the directions button is reset whenever the user
 * starts a new search, maintaining the UI's consistency and readiness for a new directions calculation.
 */
const addInputEventListeners = () => {
    const memberSearch = document.getElementById("member-search");
    const providerSearch = document.getElementById("provider-search");
    if (memberSearch) memberSearch.addEventListener('input', resetDirectionsButton);
    if (providerSearch) providerSearch.addEventListener('input', resetDirectionsButton);
};

/**
 * Adds an event listener to the directions button.
 *
 * This function targets the button identified by `DIRECTIONS_BUTTON_ID` and attaches a click event listener to it.
 * Upon clicking, it disables the button and changes its text to "Calculating...". It then attempts to find directions
 * between the selected member and provider using `findDirections`. If successful, it displays the calculated travel time
 * using `renderTravelTime` and updates the button's text to show this travel time. If an error occurs during this process,
 * the button's HTML is reset to its default state. Finally, the button is re-enabled.
 */
const addDirectionsButtonEventListener = () => {
    const button = document.getElementById(DIRECTIONS_BUTTON_ID);
    if (!button) return;

    button.addEventListener('click', async () => {
        const {selectedMember, selectedProvider} = selectionManager.getSelectedEntities();
        if (!selectedMember || !selectedProvider) return;

        button.disabled = true;
        button.textContent = "Calculating...";
        try {
            const travelTime = await findDirections(selectedMember, selectedProvider);
            renderTravelTime(travelTime);
            button.textContent = `Travel Time: ${travelTime}`;
        } catch (error) {
            button.innerHTML = DEFAULT_BUTTON_HTML;
        }
        button.disabled = false;
    });
};

/**
 * Renders the travel time in the user interface.
 *
 * This function updates the content of the 'travel-time' element with the provided travel time.
 * It checks if the 'travel-time' element exists in the DOM. If it does, it sets its text content
 * to display the travel time. This is typically called after calculating the travel time between
 * two points to provide the user with this information in a readable format.
 *
 * @param {string} travelTime - The travel time to be displayed.
 */
const renderTravelTime = (travelTime) => {
    const travelTimeElement = document.getElementById('travel-time');
    if (travelTimeElement) {
        travelTimeElement.textContent = `Travel time: ${travelTime}`;
    }
};

/**
 * Renders the directions on the map using the cached directions result.
 *
 * This function takes a directions result object and uses it to display the route on the map.
 * If there is an existing directions renderer, it removes the current directions from the map
 * before setting the new directions. This ensures that only one route is displayed at a time.
 * The function creates a new DirectionsRenderer instance if necessary, configures it to suppress
 * default markers, and sets the map and directions to render the route.
 *
 * @param {Object} directionsResult - The directions result object obtained from the Google Maps Directions API.
 */
const renderCachedDirections = (directionsResult) => {
    // Retrieve the current map instance from the mapManager.
    const map = mapManager.getMap();

    // If there is an existing directionsRenderer, remove its directions from the map.
    if (directionsRenderer) {
        directionsRenderer.setMap(null);
    }

    // Create a new DirectionsRenderer instance with suppressed markers and set the directions.
    directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});
    directionsRenderer.setDirections(directionsResult);

    // Display the directions on the map.
    directionsRenderer.setMap(map);
};


/**
 * Finds directions between a selected member and provider, utilizing caching for efficiency.
 *
 * This asynchronous function attempts to find directions between a selected member and provider. It first constructs
 * a cache key based on the IDs of the member and provider. If the directions result is already cached, it retrieves
 * the cached result to avoid unnecessary API calls. If not cached, it makes a request to the Google Maps Directions API
 * with the member's position as the origin and the provider's position as the destination, using 'DRIVING' as the travel mode.
 * Once the directions are obtained, the result is cached for future use, rendered on the map, and the travel time is calculated
 * and returned.
 *
 * @param {Object} selectedMember - The selected member, containing an `id` and `position`.
 * @param {Object} selectedProvider - The selected provider, containing an `id` and `position`.
 * @returns {Promise<string>} A promise that resolves to the calculated travel time as a string.
 */
const findDirections = async (selectedMember, selectedProvider) => {
    const cacheKey = `${selectedMember.id}-${selectedProvider.id}`;
    if (directionsCache.has(cacheKey)) {
        const cachedResult = directionsCache.get(cacheKey);
        renderCachedDirections(cachedResult);
        return calculateTravelTime(cachedResult);
    }

    const directionsService = new google.maps.DirectionsService();
    const request = {
        origin: selectedMember.position, destination: selectedProvider.position, travelMode: 'DRIVING'
    };

    const result = await directionsService.route(request);
    directionsCache.set(cacheKey, result);
    renderCachedDirections(result);
    return calculateTravelTime(result);
};

/**
 * Calculate the travel time from the directions result.
 * @param result The directions result.
 * @returns {string} The travel time.
 */
const calculateTravelTime = (result) => {
    return result.routes[0].legs[0].duration.text;
};

/**
 * Reset the directions button to its original state.
 */
export const resetDirectionsButton = () => {
    const button = document.getElementById(DIRECTIONS_BUTTON_ID);
    button.innerHTML = DEFAULT_BUTTON_HTML;
    button.disabled = true;
};

export const enableDirectionsButton = () => {
    const button = document.getElementById(DIRECTIONS_BUTTON_ID);
    button.disabled = false;
    button.innerHTML = DEFAULT_BUTTON_HTML;
}