import { Controller } from "stimulus";
import { getRequest, postRequest } from "../helpers/api";

const API = {
    SEARCH: "/api/v2/provider_supervisors",
    ADD_SUPERVISOR: "/api/v2/provider_supervisors",
};

const BACKGROUND_COLORS = {
    selected: "bg-purple-100",
    unselected: "bg-white",
};

const BORDER_COLORS = {
    selected: "border-purple-500",
    unselected: "border-gray-100",
};

const SELECTED = "selected";
const SUPERVISOR_TOGGLE = "supervisor-toggle";
const ASSIGN_BUTTON_ID = "assign-supervisor-button";

export default class extends Controller {
    static targets = ["search", "container"];

    selectedSupervisor = null;
    currentRoleFilter = null;

    connect() {
        const selectedFilter = document.querySelector(`.${SUPERVISOR_TOGGLE}.${SELECTED}`);
        this.currentRoleFilter = selectedFilter.dataset["position"];
        this.loadSupervisors();
    }

    filterRoles(event) {
        this.currentRoleFilter = event.currentTarget.dataset["position"];
        this.loadSupervisors();

        const filters = document.querySelectorAll(`.${SUPERVISOR_TOGGLE}`);
        filters.forEach(filter => {
            filter.classList.remove("bg-purple-100", SELECTED, "bg-white");
            filter.classList.add("bg-white");
        });

        event.currentTarget.classList.add("bg-purple-100", SELECTED);
        event.currentTarget.classList.remove("bg-white");
    }

    loadSupervisors() {
        document.getElementById(ASSIGN_BUTTON_ID).disabled = true;
        this.hideNoSupervisorMessage();
        this.containerTarget.innerHTML = this.loaderTemplate;

        const supervising_id = this.currentProviderId;

        getRequest(`${API.SEARCH}?supervising_id=${supervising_id}&position=${this.currentRoleFilter}`)
            .then(data => {
                if (data.supervisors.length === 0) {
                    this.showNoSupervisorMessage();
                    return;
                }
                this.renderSupervisors(data);
            })
            .catch(() => {
                this.showNoSupervisorMessage();
            });
    }

    renderSupervisors(supervisors) {
        this.containerTarget.innerHTML = supervisors.supervisors
            .map((supervisor, index) => this.supervisorTemplate
                .replace(/{{image_url}}/g, supervisor.avatar)
                .replace(/{{id}}/g, supervisor.id)
                .replace(/{{name}}/g, supervisor.name)
                .replace(/{{role}}/g, supervisor.role)
                .replace(/{{index}}/g, index)
            ).join("");
    }

    localSearch(event) {
        const searchValue = event.target.value.toLowerCase();
        const supervisors = document.querySelectorAll(".supervisor-select");

        supervisors.forEach(supervisor => {
            if (supervisor.innerText.toLowerCase().includes(searchValue)) {
                supervisor.classList.remove("hidden");
                supervisor.classList.add("flex");
            } else {
                supervisor.classList.remove("flex");
                supervisor.classList.add("hidden");
            }
        });

        if (document.querySelectorAll(".supervisor-select.flex").length === 0) {
            this.showNoSupervisorMessage();
        } else {
            this.hideNoSupervisorMessage();
        }
    }

    showNoSupervisorMessage() {
        this.containerTarget.innerHTML = "";
        const noSupervisorMessage = document.getElementById("supervisor-picker-none");
        noSupervisorMessage.style.display = "block";
    }

    hideNoSupervisorMessage() {
        const noSupervisorMessage = document.getElementById("supervisor-picker-none");
        noSupervisorMessage.style.display = "none";
    }

    async add(event) {
        console.debug("Adding supervisor", this.selectedSupervisor, this.currentProviderId);
        const supervising_id = this.currentProviderId;

        // Get the role from the button that triggered this
        const selectedRoles = event.currentTarget.dataset["supervisorRole"];
        const data = { supervisor_id: this.selectedSupervisor, supervising_id, supervisor_roles: selectedRoles };

        try {
            const response = await postRequest(API.ADD_SUPERVISOR, data);
            if (response.status === 200) {
                window.location.reload();
            } else {
                alert(`The following error occurred: ${response.message}. Please try again`);
            }
        } catch (error) {
            alert(`The following error occurred: ${error.message}. Please try again`);
        }
    }

    select(event) {
        const supervisorId = event.currentTarget.dataset.supervisorId;

        // If the supervisor is already selected, deselect it
        if (event.currentTarget.classList.contains(SELECTED)) {
            event.currentTarget.classList.remove(SELECTED, BORDER_COLORS.selected, BACKGROUND_COLORS.selected);
            this.selectedSupervisor = null;
            document.getElementById(ASSIGN_BUTTON_ID).disabled = true;
            return;
        }

        // Deselect all other supervisors
        const supervisorElements = document.querySelectorAll(".supervisor-select");
        supervisorElements.forEach(supervisor => {
            supervisor.classList.remove(BACKGROUND_COLORS.selected, BORDER_COLORS.selected, SELECTED);
            supervisor.classList.add(BORDER_COLORS.unselected);
        });

        // Select the clicked supervisor
        const supervisorElement = event.currentTarget;
        supervisorElement.classList.replace(BORDER_COLORS.unselected, BACKGROUND_COLORS.selected);
        supervisorElement.classList.add(BORDER_COLORS.selected, SELECTED);
        this.selectedSupervisor = supervisorId;

        // Enable the supervisor assign button
        document.getElementById(ASSIGN_BUTTON_ID).disabled = false;
    }

    closeModal() {
        document.getElementById('add-supervisor-dialog').close();
    }

    openModal() {
        document.getElementById('add-supervisor-dialog').showModal();
    }

    get currentProviderId() {
        return window.location.pathname.split("/")[2];
    }

    get loaderTemplate() {
        return `
            <div>
                <div class="border-purple-500 flex flex-col justify-center items-center">
                    <i class="fa fa-spinner fa-spin text-gray-500 text-3xl"></i>
                    <span class="ml-2">Loading Supervisors</span>
                </div>
            </div>`;
    }

    get supervisorTemplate() {
        return `
            <div data-tab-index="{{index}}" id="supervisor-select-{{id}}" data-supervisor-id="{{id}}" data-action="click->supervisor-select#select" class="text-ellipsis w-56 max-w-56 h-44 max-h-44 transition duration-300 ease-in-out supervisor-select flex flex-col justify-between items-center m-2 p-6 cursor-pointer rounded-xl border border-gray-100 hover:bg-purple-100">
                <img src="{{image_url}}" alt="{{name}}" class="w-16 h-16 rounded-full">
                <div class="flex flex-col items-center">
                    <span class="text-sm text-center font-bold text-gray-900 pt-2 text-ellipsis">{{name}}</span>
                    <span class="text-xs text-center text-gray-800 text-ellipsis">{{role}}</span>    
                </div>
            </div>`;
    }
}