/**
 * Initializes modal functionality by adding event listeners to modal open and close elements
 * and handling the modal's visibility based on the current page's URL.
 * This is separate from the Confirm Modal, which will inject HTML into the view.
 * This will rely on existing HTML in the view.
 */
export function initializeModal() {
    let modals = document.querySelectorAll('.modal-open');

    if (modals.length > 0) {
        modals.forEach(modal => {
            modal.addEventListener('click', (event) => {
                event.preventDefault();
                toggleModal();
            });
        });

        const modal = document.querySelector('#modal');

        let open = ["/members_hub/dashboard", "/members_hub/funding_plans"].includes(window.location.pathname) ? modal.dataset.open : false;

        if (open) {
            toggleModal();
            modal.setAttribute("open", false);
        }

        let closeModal = document.querySelectorAll('.modal-close');

        closeModal.forEach(modal => {
            modal.addEventListener('click', () => {
                toggleModal();
            });
        });

        document.onkeydown = handleKeydown;
    }
}

/**
 * Toggles the visibility of a modal by adding or removing CSS classes to the modal and the body element.
 */
const toggleModal = () => {
    const body = document.querySelector('body');
    const modal = document.querySelector('.modal');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
    body.classList.toggle('modal-active');
}

/**
 * Handles the keydown event to toggle the modal visibility when the Escape key is pressed.
 *
 * @param {KeyboardEvent} evt - The keyboard event object.
 */
const handleKeydown = (evt) => {
    evt = evt || window.event;
    let isEscape;
    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
        toggleModal();
    }
}