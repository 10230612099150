import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["service", "main", "category"];

    connect() {}

    handleSelectService() {
        this.handleSelect(this.categoryTarget);
    }

    handleSelect(selectElement) {
        const fundingType = document.querySelector("#funding_plan_funding_type");
        const url = this.data.get("url");
        clearSelectComponent(selectElement);

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                service_type_id: this.serviceTarget.value,
                main_category_id: this.mainTarget.value, // id of Core or Capacity Building
                funding_type: fundingType.value,
            }),
            credentials: "include",
            dataType: "script",
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token"),
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    data.forEach((item) => {
                        addOption(selectElement, item.id, item.name);
                    });
                } else {
                    addOption(selectElement, "", "No Category");
                }
            });
    }
}

function getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
}

function addOption(selectElement, value, text) {
    const opt = document.createElement("option");
    opt.value = value;
    opt.text = text;
    selectElement.appendChild(opt);
}

function clearSelectComponent(selectElement) {
    [...selectElement.options].forEach((option) => option.remove());
}