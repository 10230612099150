import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["content", "chevron"]

    toggle(event) {
        event.preventDefault();
        console.debug("Toggling content")
        this.contentTarget.classList.toggle("hidden");
        this.animateChevron();
    }

    animateChevron() {
        this.chevronTarget.parentElement.classList.toggle("rotate-180");
        this.chevronTarget.parentElement.classList.toggle("transform");
    }
}