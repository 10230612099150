import { constrainPoint } from "@fullcalendar/core"
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['selectbox', 'textbox']

    connect() {
        let keyword = this.data.get("keyword")
        // If "Other" selected, or empty value (eg placeholder "Select") while textboxTarget has a value
        if (this.selectboxTarget.value == keyword || (this.selectboxTarget.value == '' && this.textboxTarget.value?.length > 0)) {
            this.showTextField()
        } else {
            this.hideTextField()
        }
    }

    handleSelectChange(){
        // Keyword for showing text box, eg "Other", "Other..", "Custom"
        let keyword = this.data.get("keyword")

        // If keyword is selected from the dropdown, then the text field 
        if (this.selectboxTarget.value == keyword) {
            this.clearTextFieldValue()
            this.showTextField()
        } else {
            this.hideTextField()
        }
    }
    
    showTextField(){
        this.textboxTarget.classList.remove('hidden')
    }

    hideTextField() {
        this.textboxTarget.value = this.selectboxTarget.value
        this.textboxTarget.classList.add('hidden')
    }

    clearTextFieldValue(){
        this.textboxTarget.value = ('');
    }
}