/**
 * Renders an avatar for a given entity. If the entity has an avatar URL, it creates an <img> element.
 * Otherwise, it creates a <div> element with the entity's initials.
 *
 * @param {Object} entity - The entity object containing avatar information.
 * @param {Object} initialsOptions - Options for rendering the initials avatar.
 * @param {string} initialsOptions.width - The width and height for the avatar elements.
 * @returns {HTMLElement} The created avatar element.
 */
export const renderEntityAvatar = (entity, initialsOptions) => {

    if (entity.avatar && entity.avatar !== "") {
        const image = document.createElement("img");
        image.src = entity.avatar;
        image.className = "user-avatar mb-2";
        image.style.minWidth = initialsOptions.width;
        image.style.width = initialsOptions.width;
        image.style.height = initialsOptions.width;
        image.alt = entity.initials;
        return image;
    }

    const initialsAvatar = document.createElement("div");
    initialsAvatar.innerHTML = entity.initials;
    initialsAvatar.className = "initials-avatar mb-2 " + entity.avatar_background_colour
    initialsAvatar.style.minWidth = initialsOptions.width;
    initialsAvatar.style.height = initialsOptions.width;

    return initialsAvatar;
}

