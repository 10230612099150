import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["inputField", "outputField" ]

  initialize() {
    if (this.data.get("min-words")) {
      this.updateWordCount()
    } else if (this.data.get("min-chars")) {
      this.updateCharsCount()
    }
  }

  countWords(inputField, outputField, wordLimit) {
    var count = inputField.value ? inputField.value.split(' ').filter(Boolean).length : 0
    
    if (count == 1) {
      var message = `1 word / ${wordLimit}`
    } else {
      var message = `${count} words / ${wordLimit}`
    }
    this.displayOutputMessage(outputField, message, count, wordLimit)
  }

  countChars(inputField, outputField, wordLimit) {
    var count = inputField.value ? inputField.value.replace(/\s/g, '').length : 0
    
    if (count == 1) {
      var message = `1 character / ${wordLimit}`
    } else {
      var message = `${count} characters / ${wordLimit}`
    }

    this.displayOutputMessage(outputField, message, count, wordLimit)
  }

  displayOutputMessage(outputField, message, count, wordLimit) {
    outputField.textContent = message

    if (count < wordLimit) {
      outputField.classList.add("text-ascendRed")
      outputField.classList.remove("text-ascendGreen-dark")
    } else {
      outputField.classList.remove("text-ascendRed")
      outputField.classList.add("text-ascendGreen-dark")
    }
  }

  updateWordCount() {
    this.countWords(this.inputFieldTarget, this.outputFieldTarget, this.data.get("min-words"))
  }

  updateCharsCount() {
    this.countChars(this.inputFieldTarget, this.outputFieldTarget, this.data.get("min-chars"))
  }
}
