/**
 * Creates a debounced function that delays invoking the provided function until after
 * a specified wait time has elapsed since the last time the debounced function was invoked.
 *
 * @param {Promise<void>} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} - A new debounced function.
 */
export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}