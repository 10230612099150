import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
  }

  toggle() {
    if (this.inputTarget.classList.contains("hidden")) {
      this.inputTarget.classList.remove("hidden");
      this.inputTarget.classList.add("block");
    } else {
      this.inputTarget.classList.add("hidden");
      this.inputTarget.classList.remove("block");
    }
  }

  justtoggle(){
    this.inputTarget.classList.remove("hidden");
    this.inputTarget.classList.add("block");
  }

  untoggle() {
    this.inputTarget.classList.add("hidden");
    this.inputTarget.classList.remove("block");
  }

  toggleGrid() {
    if (this.inputTarget.classList.contains("hidden")) {
      this.inputTarget.classList.remove("hidden");
      this.inputTarget.classList.add("grid");
    } else {
      this.inputTarget.classList.add("hidden");
      this.inputTarget.classList.remove("grid");
    }
  }
}
