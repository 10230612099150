import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "name", "counter" ]

  countCharacters() {    
    let myString = this.nameTarget.value;
    let withoutSpace = myString.replace(/ /g,"");
    let length = withoutSpace.length;
    this.counterTarget.innerText = length;
  }
}