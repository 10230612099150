import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['option', 'button']

  connect() {
    this.optionTargets.forEach(option => {
      if (option.checked === true) {
        let targetElement = this.buttonTargets.find(el => el.id === option.value)
        targetElement.classList = ['block']
      }
    })
  }

  selectRadioOption(e) {

    this.buttonTargets.forEach(targetElement => {
      if (e.target.value === targetElement.id) {
        targetElement.classList = ['block']
      } else {
        targetElement.classList = ['hidden']
      }
    })

    this.optionTargets.forEach(targetElement => {
      if (e.target.value === targetElement.value) {
        targetElement.classList = ['selectedBtn']
      } else {
        targetElement.classList = ['unselectedBtn']
      }
    })
  }
}
