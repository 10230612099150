import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selected", "option"];

  select(event) {
    let value = event.target.closest(".rating-element").id
    this.selectedTarget.value = value
  }

  hover(event) {
    let value = event.target.closest(".rating-element").id
    this.optionTargets.forEach(option => {
        if (option.id <= value) {
            option.classList.remove("text-grey-300");
            option.classList.add("text-yellow-300");
        } else {
            option.classList.remove("text-yellow-300");
            option.classList.add("text-grey-300");
        }
    })
  }

  clear() {
    let value = this.selectedTarget.value || 0

    this.optionTargets.forEach(option => {
        if (option.id <= value) {
            option.classList.remove("text-grey-300");
            option.classList.add("text-yellow-300");
        } else {
            option.classList.remove("text-yellow-300");
            option.classList.add("text-grey-300");
        }
    })
  }
}
