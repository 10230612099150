import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "attendee", "searchTags" ]

  connect() {
    // this.selectAll(false)
  }

  selectOption(event) {
    const selectType = event.target.id

    if (selectType === "all") {
      this.selectAll(event.target.checked)
    } else {
      this.selectByType(selectType, event.target.value, event.target.selectedOptions[0].text)

      // Clear select field
      document.getElementById(selectType).options[0].selected = true 
    }
  }

  selectAll(checked) {
    // clear pills
    this.searchTagsTarget.innerHTML = ""
    // select or unselect all
    this.checkElements(checked, "all")
  }

  selectByType(selectType, value, text) {
    const pill = this.searchTagsTarget.querySelector(`[data-value="${value}"]`)

    if (pill) {
      pill.remove()
      this.checkElements(false, selectType, value)
    } else {
      this.searchTagsTarget.insertAdjacentHTML("beforeend", this.pillTemplate(selectType, value, text))
      this.checkElements(true, selectType, value)
    }
  }

  checkElements(checked, selectType, value) {
    this.attendeeTargets.map((el) => {
      if (selectType == "all" || el.dataset[selectType] === value) {
        el.checked = checked;
      }
    });
  }

  clickRemovePill(event) {
    event.preventDefault()

    let itemToRemove = event.currentTarget.parentNode
    let value = itemToRemove.dataset.value
    let selectType = itemToRemove.dataset.selectType

    this.selectByType(selectType, value, null)
  }

  pillTemplate(selectType, value, text) {
    if (this.disabledValue) {
      return `<div class="multiselect__pill" data-value="${value}" title="${text}">
        <span class="multiselect__pill-text">${text}</span>
      </div>`
    } else {
      return `<div class="multiselect__pill m-2" data-select-type="${selectType}" data-value="${value}" title="${text}">
        <span class="multiselect__pill-text">${text}</span>
        <span class="multiselect__pill-delete" data-action="click->attendees#clickRemovePill">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="12px" height="12px">
            <path d="M25.707,6.293c-0.195-0.195-1.805-1.805-2-2c-0.391-0.391-1.024-0.391-1.414,0c-0.195,0.195-17.805,17.805-18,18c-0.391,0.391-0.391,1.024,0,1.414c0.279,0.279,1.721,1.721,2,2c0.391,0.391,1.024,0.391,1.414,0c0.195-0.195,17.805-17.805,18-18C26.098,7.317,26.098,6.683,25.707,6.293z"/>
            <path d="M23.707,25.707c0.195-0.195,1.805-1.805,2-2c0.391-0.391,0.391-1.024,0-1.414c-0.195-0.195-17.805-17.805-18-18c-0.391-0.391-1.024-0.391-1.414,0c-0.279,0.279-1.721,1.721-2,2c-0.391,0.391-0.391,1.024,0,1.414c0.195,0.195,17.805,17.805,18,18C22.683,26.098,23.317,26.098,23.707,25.707z"/>
          </svg>
        </span>
      </div>`
    }
  }
}