export const addValueToArrayInput = (input, value) => {
    try {
        // Get the value and parse it as JSON
        const values = input.value === '' ? [] : JSON.parse(input.value);
        if (Array.isArray(values)) {
            values.push(value);
            input.value = JSON.stringify(values);
            input.dispatchEvent(new Event('change'));
        }
    } catch (e) {
        console.error(`Error parsing the element "${input.id}" value to array`, e)
    }


}

export const removeValueFromArrayInput = (input, value) => {
    try {
        const values = input.value === '' ? [] : JSON.parse(input.value);
        if (Array.isArray(values)) {
            const newValues = values.filter(v => v !== value);
            input.value = JSON.stringify(newValues);
            input.dispatchEvent(new Event('change'));
        }
    } catch (e) {
        console.error(`Error parsing the element "${input.id}" value to array`, e)
    }
}