import { Confirm } from "./confirm";
import Rails from "@rails/ujs";

/**
 * Override Rails allowAction to show a custom confirm dialog.
 * This overrides the default in the @rails/ujs package
 * @param {HTMLElement} element - The element that triggered the action.
 * @returns {boolean} - Returns true if the action is allowed, false otherwise.
 */
Rails.allowAction = function(element) {
    const message = element.getAttribute('data-confirm');
    if (!message) { return true; }

    // Show our 'confirm' dialog and prevent default action
    showConfirmDialog(element, message);
    return false;
};

/**
 * Function to handle the confirmed action.
 * This overrides the Rails.confirmed in the @rails/ujs package
 * @param {HTMLElement} element - The element that triggered the action.
 */
Rails.confirmed = function(element) {
    // Remove data-confirm to prevent infinite loop
    element.removeAttribute('data-confirm');

    // Trigger the original click event
    let event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    element.dispatchEvent(event);
};

/**
 * Show confirm dialog using our Confirm class.
 * @param {HTMLElement} element - The element that triggered the action.
 * @param {string} message - The confirmation message to display.
 */
function showConfirmDialog(element, message) {
    Confirm.confirm('Confirm Action', message)
        .then(result => {
            if (result) {
                Rails.confirmed(element);
            }
        })
        .catch(error => {
            console.error('Confirmation error:', error);
        });
}

/**
 * Override Rails.confirm to use our custom dialog.
 * @param {string} message - The confirmation message to display.
 * @param {HTMLElement} element - The element that triggered the action.
 */
Rails.confirm = function(message, element) {
    showConfirmDialog(element, message);
};