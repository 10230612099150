import { Controller } from "stimulus"

export default class extends Controller {

  click() {
    Turbolinks.visit(this.url)
  }

  get url() {
    return this.targets.find('url').href
  }
}
