/**
 * Initializes the Stripe payment integration by loading the necessary elements and mounting them.
 *
 * @return {undefined}
 */
export function initializeStripe() {

    try {
        if (document.querySelectorAll("#cc-payment-form").length) {
            loadStripeElements().then(({stripe, elements}) => {
                mountCardElement(stripe, elements)
            });
        }

        if (document.querySelectorAll("#dd-payment-form").length) {
            loadStripeElements().then(({stripe, elements}) => {
                mountBankElement(stripe, elements)
            });
        }
    } catch (e)
    {
        console.error('Error loading Stripe:', e);
        throw(e);
    }

}


function mountBankElement(stripe, elements) {
    let form = document.getElementById('dd-payment-form');
    // Global variable to store the submit button text.
    const submitButton = form.querySelector('button[type=submit]');

    const updateSubmitButtonPayText = (newText) => {
        submitButton.textContent = newText;
        submitButtonPayText = newText;
    };

    // Prepare the styles for Elements.
    const style = {
        base: {
            iconColor: '#666ee8',
            color: '#31325f',
            fontWeight: 400,
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#aab7c4',
            },
            ':-webkit-autofill': {
                color: '#666ee8',
            },
        },
        invalid: {
            color: '#e53e3e',
            iconColor: '#e53e3e'
        }
    };

    // Create a Card Element and pass some custom styles to it.
    let auBankAccountElement = elements.create('auBankAccount', {style});
    // Mount the Card Element on the page.
    auBankAccountElement.mount('#dd-element');

    // Monitor change events on the Card Element to display any errors.
    auBankAccountElement.on('change', ({error}) => {
        const cardErrors = document.getElementById('card-errors');
        if (error) {
            cardErrors.textContent = error.message;
            cardErrors.classList.remove('hidden');
            cardErrors.classList.add('visible');
            submitButton.disabled = true;
        } else {
            cardErrors.classList.remove('visible');
            cardErrors.classList.add('hidden');
        }
        // Re-enable the Pay button.
        submitButton.disabled = false;
    });


    let accountHolder = document.getElementById('account-holder');
    let accountHolderEmail = document.getElementById('account-email');
    let setupIntentClientSecret = submitButton.dataset.secret;

    submitButton.addEventListener('click', function (event) {
        event.preventDefault();

        submitButton.disabled = true;
        submitButton.textContent = 'Processing…';

        let theFormURL = form.getAttribute('action');

        stripe.confirmAuBecsDebitSetup(setupIntentClientSecret, {
            payment_method: {
                au_becs_debit: auBankAccountElement,
                billing_details: {
                    name: accountHolder.value,
                    email: accountHolderEmail.value,
                },
            },
        }).then(function (result) {
            submitButton.textContent = 'Submit Payment Details';

            if (result.error) {
                let errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Display success message
                createPaymentMethod(theFormURL, result.setupIntent)
            }
        });
    });

}

function mountCardElement(stripe, elements) {
    let form = document.getElementById('cc-payment-form');
    // Global variable to store the submit button text.
    const submitButton = form.querySelector('button[type=submit]');

    const updateSubmitButtonPayText = (newText) => {
        submitButton.textContent = newText;
        submitButtonPayText = newText;
    };

    // Prepare the styles for Elements.
    const style = {
        base: {
            iconColor: '#666ee8',
            color: '#31325f',
            fontWeight: 400,
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
                color: '#aab7c4',
            },
            ':-webkit-autofill': {
                color: '#666ee8',
            },
        },
        invalid: {
            color: '#e53e3e',
            iconColor: '#e53e3e'
        }
    };

    // Create a Card Element and pass some custom styles to it.
    let card = elements.create('card', {style, hidePostalCode: true});

    // Mount the Card Element on the page.
    card.mount('#card-element');

    // Monitor change events on the Card Element to display any errors.
    card.on('change', ({error}) => {
        const cardErrors = document.getElementById('card-errors');
        if (error) {
            cardErrors.textContent = error.message;
            cardErrors.classList.remove('hidden');
            cardErrors.classList.add('visible');
        } else {
            cardErrors.classList.remove('visible');
            cardErrors.classList.add('hidden');
        }
        // Re-enable the Pay button.
        submitButton.disabled = false;
    });


    let cardholderName = document.getElementById('cardholder-name');
    let setupIntentClientSecret = submitButton.dataset.secret;

    submitButton.addEventListener('click', function (event) {
        event.preventDefault();

        submitButton.disabled = true;
        submitButton.textContent = 'Processing…';

        let theFormURL = form.getAttribute('action');

        stripe.confirmCardSetup(setupIntentClientSecret, {
            payment_method: {
                card: card,
                billing_details: {
                    name: cardholderName.value,
                },
            },
        }).then(function (result) {
            submitButton.textContent = 'Submit Payment Details';

            if (result.error) {
                let errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Display success message
                createPaymentMethod(theFormURL, result.setupIntent)
            }
        });
    });
}



function createPaymentMethod(theFormURL, setupIntent) {


    import("axios").then((axios_module) => {

        const axios = axios_module.default;

        const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content

        axios.post(theFormURL, {
            payment_details: {
                payment_id: setupIntent.payment_method,
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    window.location.pathname = response.data.redirectPath;
                }
            })
            .catch(function (error) {
                console.log('error', error)
            });
    });


}


/**
 * Loads the Stripe Elements library asynchronously and returns a promise.
 *
 * @returns {Promise<{ stripe: Stripe, elements: StripeElements }>} A promise that resolves to an object containing the stripe and elements instances.
 */
function loadStripeElements() {
    // Create Promise to load stripe
    return new Promise(async (resolve, reject) => {
        try {
            const {loadStripe} = await import('@stripe/stripe-js');
            let publishable_key = document.querySelector("meta[name='stripe-publishable-key']").content
            const stripe = await loadStripe(publishable_key);
            // Create an instance of Elements.
            let elements = stripe.elements();
            resolve({stripe, elements})
        } catch (error) {
            reject(error)
        }
    })
}
