/**
 * This function is used to find an HTML element based on a base ID.
 * It constructs a regular expression using the provided base ID, allowing for optional hyphens or underscores before or after the base ID.
 * It then queries the document for all input and select elements and iterates over them.
 * If an element's ID matches the regular expression, that element is returned.
 * If no matching element is found, the function returns null.
 *
 * @param {string} baseId - The base ID to search for. Hyphens and underscores are optional before and after the base ID.
 * @returns {HTMLElement|null} The first HTML element with an ID that matches the base ID, or null if no matching element is found.
 */
export const findElement = (baseId) => {
    const regex = new RegExp(`(^|[-_])${baseId}([-_]|$)`, 'i');
    const elements = document.querySelectorAll("input, select");
    for (const element of elements) {
        if (regex.test(element.id)) {
            return element;
        }
    }
    return null;
};
