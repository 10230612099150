import {PROVIDER_FOCUS_BUTTON_CLASS} from "./interactions";
import {renderEntityAvatar} from "../../helpers/render_avatar";

export const renderProvidersList = (providers) => {
    const providerListContainer = document.getElementById("provider-list");

    providerListContainer.innerHTML = "";
    providers.forEach(provider => {
        try {
            providerListContainer.innerHTML += providerListItem(provider);
        } catch (error) {
            console.error("Error rendering provider list item: ", error);
        }

    });

}

const providerListItem = (provider) => {

    if (!provider) return;

    return `
<div>
        <div class="flex justify-between items-center p-4 border-b border-gray-200">
            <div class="flex items">
                ${renderEntityAvatar(provider, {width: "3.5rem", fontSize: "1.5rem", backgroundColor: provider.avatar_background_colour}).outerHTML}
                <div class="ml-4">
                    <span class="text-xs ${provider.work_status_icon.htmlClass}">
                        ${provider.work_status_icon.text}
                    </span>
                    <span class="text-xs ${provider.type_of_provider.htmlClass}">
                        ${provider.type_of_provider.text}
                    </span>
                    <div class="flex flex-row items-center justify-start mt-1">
                        <button data-provider-id="${provider.id}" class="${PROVIDER_FOCUS_BUTTON_CLASS.substring(1)} text-black opacity-50 hover:text-ascendPurple-dark transition p-1 -ms-1 hover:opacity-100">
                            <i class="fas fa-location-crosshairs cursor-pointer pointer-events-none"></i>    
                        </button>
                        <h2 class="text-lg font-semibold">${provider.first_name} ${provider.last_name}</h2>
                    </div>
                    <p class="text-gray-700">${provider.full_address ?? "No address entered"}</p>
                </div>
            </div>
            <button data-provider-id="${provider.id}" class="provider-map-select-button cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md group transition" title="Select ${provider.first_name}">
            <i class="pointer-events-none fas fa-long-arrow-right group-hover:translate-x-0.5 group-hover:scale-x-110 transition"></i>
</button>
</div>`

}