/**
 * @class Confirm
 * @description A class to handle confirmation modals.
 */
export class Confirm {
    /**
     * @constructor
     * @param {HTMLElement|null} container - The container element for the modal. If null, a new container will be created.
     */
    constructor(container = null) {
        this.container = container || this.getOrCreateContainer();
    }

    /**
     * @method getOrCreateContainer
     * @description Gets the existing container or creates a new one if it doesn't exist.
     * @returns {HTMLElement} The container element.
     */
    getOrCreateContainer() {
        let container = document.getElementById('confirm-modal-container');
        if (!container) {
            container = document.createElement('div');
            container.id = 'confirm-modal-container';
            document.body.appendChild(container);
        }
        return container;
    }

    /**
     * Display a confirmation modal with the given title and message.
     * @param {string} title - The title of the modal.
     * @param {string} message - The message of the modal.
     * @returns {Promise<boolean>} A promise that resolves to true if confirmed, false otherwise.
     */
    confirm(title, message) {
        return new Promise((resolve) => {
            this.container.innerHTML = this.modalTemplate(title, message);
            const modal = this.container.querySelector('dialog');

            // Prevent default closing behaviors (like ESC key)
            modal.addEventListener('cancel', (event) => {
                event.preventDefault();
            });

            // Prevent clicks outside from closing
            modal.addEventListener('click', (event) => {
                if (event.target === modal) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            });

            const cleanup = () => {
                if (modal && modal.parentNode) {
                    modal.parentNode.removeChild(modal);
                }
            };

            const confirmButton = modal.querySelector('.modal-confirm');
            confirmButton.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                modal.close();
                cleanup();
                resolve(true);
            }, { once: true });

            const cancelButton = modal.querySelector('.modal-cancel');
            cancelButton.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                modal.close();
                cleanup();
                resolve(false);
            }, { once: true });

            // Show modal after all handlers are set up
            requestAnimationFrame(() => {
                modal.showModal();
                confirmButton.focus();
            });

            // Focus the confirm button when the modal is shown
            modal.querySelector('.focus-modal-animation').addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                modal.classList.add('wiggle');
                setTimeout(() => modal.classList.remove('wiggle'), 250);
            });
        });
    }

    /**
     * @static
     * @method confirm
     * @description Static method to display a confirmation modal with the given title and message.
     * @param {string} title - The title of the modal.
     * @param {string} message - The message of the modal.
     * @returns {Promise<boolean>} A promise that resolves to true if confirmed, false otherwise.
     */
    static confirm(title, message) {
        return new Confirm().confirm(title, message);
    }

    /**
     * Generate the HTML template for the modal.
     * @param {string} title - The title of the modal.
     * @param {string} message - The message of the modal.
     * @returns {string} The HTML template string.
     */
    modalTemplate(title, message) {
        return `
<dialog class="fixed inset-0 z-40 flex items-center justify-center rounded-lg">
    <div class="fixed inset-0 bg-black opacity-20 focus-modal-animation m-0 p-6"></div>
    <div  class="relative bg-white rounded-lg max-w-md w-full z-50 m-0 p-6">
            <h3 class="text-lg font-medium text-gray-900 mb-4 modal-title">${title}</h3>
            <div class="text-gray-500 mb-6 modal-content">${message}</div>
            <div class="flex justify-end space-x-2">
                <button type="button" class="modal-cancel back-button">Cancel</button>
                <button type="button" class="modal-confirm submit-button">Confirm</button>
            </div>
    </div>
</dialog>`
    }
}

export default Confirm;