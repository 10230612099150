import {Controller} from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {

    static targets = ["target", "content"]

    connect() {
        tippy(this.targetTarget, {
            content: this.contentTarget,
        })
    }

}