import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "input", "removeField"];

  removeSignature() {
    this.containerTarget.style.display = "none";
    this.inputTarget.value = "";
    this.removeFieldTarget.value = "true";
  }
}