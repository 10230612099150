import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element" ]

  connect() {
  }

  toggle() {
    this.data.set("state", 1 - this.data.get("state"))

    this.elementTargets.forEach((element) => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden')
        element.classList.add('block')
      } else {
        element.classList.remove('block')
        element.classList.add('hidden')
      }
    })
  }
}
