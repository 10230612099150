/**
 * Represents a manager for handling map-related functionalities within the application.
 * This class allows for the initialization, retrieval, and manipulation of a map object.
 *
 */
class MapManager {
    /**
     * Constructs a new instance of MapManager.
     */
    constructor() {
        this.map = null; // Initially, there is no map object.
    }

    /**
     * Sets the map object for the manager.
     * @param {Object} newMap - The new map object to be managed.
     */
    setMap(newMap) {
        this.map = newMap;
    }

    /**
     * Retrieves the currently managed map object.
     * @returns {Object|null} The current map object or null if no map has been set.
     */
    getMap() {
        return this.map;
    }
}

// Export the MapManager class for use in other modules.
// This will behave as a singleton class, allowing for a single instance of the manager to be used across the application.
export const mapManager = new MapManager();