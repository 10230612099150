import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["tab", "content"];

    connect() {
        this.showTab(this.tabTargets[0]);
    }

    switch(event) {
        event.preventDefault();
        this.showTab(event.currentTarget);
    }

    showTab(tab) {
        this.tabTargets.forEach(t => t.classList.remove('border-l', 'border-t', 'border-r', 'rounded-t', 'text-ascendPurple'));
        this.tabTargets.forEach(t => t.classList.add('text-ascendPurple', 'hover:text-ascendPurple-dark'));
        this.contentTargets.forEach(content => content.classList.add('hidden'));

        tab.classList.add('border-l', 'border-t', 'border-r', 'rounded-t', 'text-ascendPurple');
        tab.classList.remove('text-ascendPurple', 'hover:text-ascendPurple-dark');

        const target = this.contentTargets.find(content => content.id === tab.getAttribute('href').substring(1));
        target.classList.remove('hidden');
    }
}