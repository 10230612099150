/**
 * Keeps for elements in sync, having the concept of a "parent" field that updates the "child" fields.
 * - The parent field is the one that triggers the change event, and the child fields are the ones that are updated.
 * - The parent field should be updated by the user, and the child fields should be updated by the system.
 * - Any new child elements that are added to the DOM will automatically be updated to match the parent field.
 */

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["parent", "child"]

    connect() {
        this.parentTarget.addEventListener("change", this.updateChildFields.bind(this));
        this.observeChildElements();
    }

    updateChildFields() {
        this.childTargets.forEach((child) => {
            child.value = this.parentTarget.value
        })
    }

    /**
     * Observes changes in the DOM to detect when new child elements are added.
     * When a new child element is added, it updates the child element's value to match the parent element's value.
     */
    observeChildElements() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    mutation.addedNodes.forEach((node) => {
                        console.log("Node added:", node);
                        if (node.nodeType === Node.ELEMENT_NODE) {
                            const childNodes = node.querySelectorAll("[data-target='field-sync.child']");
                            childNodes.forEach((childNode) => {
                                console.log("Node matches child target selector:", childNode);
                                childNode.value = this.parentTarget.value;
                            });
                        }
                    });
                }
            });
        });

        console.log("Starting observer on element:", this.element);
        observer.observe(this.element, { childList: true, subtree: true });
    }


}