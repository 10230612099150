/**
 * Manages the selection of members and providers within the matchmaking system.
 * This manager keeps track of the currently selected member and provider, allowing
 * for easy retrieval and update of these selections. It also provides a method to
 * get both selected entities at once for convenience.
 */
const selectionManager = (() => {
    // Holds the currently selected member, initially null
    let selectedMember = null;
    // Holds the currently selected provider, initially null
    let selectedProvider = null;

    /**
     * Retrieves the currently selected member.
     * @returns {Object|null} The selected member or null if no member is selected.
     */
    const getSelectedMember = () => selectedMember;

    /**
     * Retrieves the currently selected provider.
     * @returns {Object|null} The selected provider or null if no provider is selected.
     */
    const getSelectedProvider = () => selectedProvider;

    /**
     * Retrieves both the selected member and provider.
     * @returns {Object} An object containing the selected member and provider.
     */
    const getSelectedEntities = () => ({
        selectedMember: selectedMember,
        selectedProvider: selectedProvider,
    });

    /**
     * Sets the selected member. If the input is null, the selected member is cleared.
     * @param {Object|null} member - The member to select or null to clear the selection.
     */
    const setSelectedMember = (member) => {
        if (!member) {
            selectedMember = null;
            return;
        }
        selectedMember = {...member, position: position(member.latitude, member.longitude)};
    };

    /**
     * Sets the selected provider. If the input is null, the selected provider is cleared.
     * @param {Object|null} provider - The provider to select or null to clear the selection.
     */
    const setSelectedProvider = (provider) => {
        if (!provider) {
            selectedProvider = null;
            return;
        }
        selectedProvider = {...provider, position: position(provider.latitude, provider.longitude)};
    };

    /**
     * Helper function to create a position object from latitude and longitude values.
     * @param {number} lat - The latitude value.
     * @param {number} long - The longitude value.
     * @returns {Object} An object representing the position with `lat` and `lng` properties.
     */
    const position = (lat, long) => {
        return {
            lat: Number(lat),
            lng: Number(long)
        }
    }

    // Expose the public methods
    return {
        getSelectedMember,
        getSelectedProvider,
        getSelectedEntities,
        setSelectedMember,
        setSelectedProvider,
    };
})();

export default selectionManager;