import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.toggleBillableVisibility()
  }

  // This function is called when the value of the invoice_funding_category_id changes
  // It will hide all the billable items that do not belong to the selected funding category
  toggleBillableVisibility(event) {
    // Find <select> elements with the data-target="invoice-items.selectedBillable" attribute
    document.querySelectorAll('[data-target="invoice-items.billableItems"]').forEach(selectedBillable => {
      // When Funding Category Id changes -> set to default (This is to avoid the selected option to be hidden)
      if (event && event.target.id === "invoice_funding_category_id") { selectedBillable.selectedIndex = 0 }
      
      Array.from(selectedBillable.options).forEach(option => {
        const selectedFundingCategoryId = document.getElementById('invoice_funding_category_id').value
        if (option.dataset["parentId"] === selectedFundingCategoryId) {
          option.hidden = false
        } else {
          option.hidden = true
        }
      })
    })
  }
}
