import { Controller } from "stimulus";
import {initializeFlatpickrDateTime} from "../packs/utils/flatpickr_utils";

export default class extends Controller {
  static targets = ["startTime", "endTime", 'duration'];

  connect() {
    this.setStartTimes()

    // Listen for the custom event to reset start times when an association is removed
    const removeEventName = this.data.get("removeEventName")
    document.addEventListener(removeEventName, this.removeElement.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener(removeEventName, this.removeElement.bind(this));
  }

  removeElement() {
    this.setStartTimes(null, "remove")
  }

  setStartTimes(event, actionName) {
    this.startTimeTargets.forEach((startTimeElement, index) => {
      let endTimeElement = this.endTimeTargets[index];
      let defaultStartTime = actionName === "remove" ? this.endTimeTargets[index - 1]?.value : null
  
      this.enableStartTimeBasedOnIndex(startTimeElement, index, defaultStartTime)
      this.enableEndTimePickr(endTimeElement, startTimeElement)
      this.calculateDuration(this.durationTargets[index], startTimeElement.value, endTimeElement.value)
    })
  }


  enableStartTimeBasedOnIndex(startTimeElement, index, defaultStartTime) {
    if(index === 0) {
      // First Start Time is always enabled
      this.enableStartTimePickr(startTimeElement)
    } else {
       // Other start times get values from the previous end time
      this.disableStartTimeElement(startTimeElement)

      // if triggered by a click - set default values. If triggered by connect() method - do nothing (do not overrride values)

      if(defaultStartTime) {
        startTimeElement.value = defaultStartTime
      }
    }
  }

  // Set start time of the next element to the end time of the current element.
  selectEndTime(event) {
    let elementShouldChange = false

    this.endTimeTargets.forEach((endTimeElement, index) => {  
      // Find element which triggered the event
      if (endTimeElement.id === event.target.id) {
        this.calculateDuration(this.durationTargets[index], this.startTimeTargets[index].value, endTimeElement.value)
        const isLastInput = index === this.endTimeTargets.length - 1;
        // If this input is NOT the last input, set the start time of the next input to the end time of this input
        if (!isLastInput) {
          const nextStartTimeElement = this.startTimeTargets[index+1];
          nextStartTimeElement.value = endTimeElement.value;
          elementShouldChange = true
        }
      } else if (elementShouldChange) {
        this.enableEndTimePickr(endTimeElement, this.startTimeTargets[index]);
      }
    })
  }

  enableStartTimePickr(startTimeElement) {
    startTimeElement.setAttribute("readonly", false);

    startTimeElement.classList.remove('disabled')
    initializeFlatpickrDateTime(startTimeElement)
  }

  disableStartTimeElement(startTimeElement) {
    startTimeElement.setAttribute("readonly", "readonly")
    startTimeElement.classList.add('disabled')
  }

  enableEndTimePickr(endTimeElement, startTimeElement) {
    endTimeElement.dataset['minDateTime'] = startTimeElement.value;
    endTimeElement.setAttribute("readonly", false);

    //  If Start time is after End Time - nullify end time
    if (endTimeElement.value && (new Date(startTimeElement.value) > new Date((endTimeElement.value)) )) {
      endTimeElement.value = null;
    }
    // if endTimeElement.value
    initializeFlatpickrDateTime(endTimeElement); // reset the flatpickr
  }

  appendElement() {
    const appendedElementIndex = this.endTimeTargets.length - 1
    const startTimeElement = this.startTimeTargets[appendedElementIndex]
    const endTimeElement = this.endTimeTargets[appendedElementIndex];

    this.enableStartTimeBasedOnIndex(startTimeElement, appendedElementIndex, this.endTimeTargets[appendedElementIndex - 1]?.value)
    this.enableEndTimePickr(endTimeElement, startTimeElement)
  }

  calculateDuration(durationElement, startTime, endTime) {
    if (endTime && startTime) {
      const duration = (new Date(endTime) - new Date(startTime)) / 3600 / 1000;
      if (duration < 0) {
        durationElement.innerText = `0 Hrs`;
        return;
      }
      // round decimal places if float, do not round if integer
      let hours = Math.floor(duration)
      let minutes =  (duration % 1 * 60).toFixed(0)
      minutes = minutes < 10 ? `0${minutes}` : minutes
      hours = hours < 1 ? `0${hours}` : hours
  
      durationElement.innerText = `${hours}:${minutes} Hrs`;
    } else {
      durationElement.innerText = `0 Hrs`;
    }
  }
}