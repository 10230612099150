import {Controller} from "stimulus";
import {getRequest} from "../helpers/api";

const WAGE_LEVEL_API = "/api/v2/dashboard/career_progress_level";
const COMMISSION_CALCULATION_API = "/api/v2/dashboard/calculate_commission";

const SALARY_FORMATTER = new Intl.NumberFormat('en-AU');
const COMMISSION_DEPENDANT_CLASS = "commission-dependant";

export default class extends Controller {
    static targets = [
        "core_funds_input", "capacity_building_input", "billable_hours_input",
        "accessible_commission_output", "commission_percentage_output",
        "commission_output", "annual_salary_output", "annual_and_commission_output",
        "wage_level_label", "reset"
    ];

    currentWageLevel = 0;
    initialWageLevel = 0;
    wageLevels = {};
    wageLevelLimits = {start: null, end: null};
    fullTimeEquivalent = 1.0;
    hoursPerWeek = 0;
    current_request_stack = [];
    awaiting_request = true;

    initial_animation_triggered = false;

    connect() {
        this.wageLevelRequest().then(wageLevelResponse => {
            if (wageLevelResponse.wage_level) {
                this.setupInitialValues(wageLevelResponse);
                this.addWageLevelToCache(wageLevelResponse.wage_level);
                this.onWageLevelChange();
            } else {
                console.error("No wage level found");
                document.getElementById("remuneration-estimator-container").classList.add("hidden");
            }
        });

        this.addInputEventListeners();
    }

    setupInitialValues(wageLevelResponse) {
        this.setDefaultValueAndDispatchInput("billable_hours_input", wageLevelResponse.daily_billables);
        this.setDefaultValueAndDispatchInput("core_funds_input", wageLevelResponse.core_funds);
        this.setDefaultValueAndDispatchInput("capacity_building_input", wageLevelResponse.capacity_building);
        this.fullTimeEquivalent = wageLevelResponse.full_time_equivalent;
        this.hoursPerWeek = wageLevelResponse.weekly_hours;
        this.currentWageLevel = wageLevelResponse.wage_level.id;
        this.initialWageLevel = wageLevelResponse.wage_level.id;
    }

    setDefaultValueAndDispatchInput(targetName, value) {
        let target = this[`${targetName}Target`];
        target.value = value;
        target.dataset.defaultValue = value;
        target.dispatchEvent(new Event("input"));
    }

    addInputEventListeners() {
        this.core_funds_inputTarget.addEventListener("change", () => this.onParameterChange());
        this.capacity_building_inputTarget.addEventListener("change", () => this.onParameterChange());
        this.billable_hours_inputTarget.addEventListener("change", () => this.onParameterChange());
    }

    updateEstimates(commission, salary, poolPercentage, position) {
        if (!this.wageLevels[this.currentWageLevel]) return;

        const wageLevel = this.wageLevels[this.currentWageLevel].wageLevel;
        const commissionDependantElements = document.querySelectorAll(`.${COMMISSION_DEPENDANT_CLASS}`);
        this.toggleCommissionDependantElements(commissionDependantElements, wageLevel.commission);

        this.toggleSliders(position)

        document.getElementById("dashboard-annual-salary").parentElement.classList.add("showing");
        this.updateOutputs(commission, salary, poolPercentage);
    }

    toggleSliders(position) {
        const activationCbFunds = document.getElementById("activation-cb-funds");
        const activationCoreFunds = document.getElementById("activation-core-funds");

        if (position === "senior_health_professional_position" || position === "researcher_position") {
            this.showClassWithAnimation(activationCbFunds);
            this.showClassWithAnimation(activationCoreFunds);
        } else if (position === "health_professional_position" || position === "new_graduate_position") {
            this.hideClassWithAnimation(activationCbFunds);
            this.showClassWithAnimation(activationCoreFunds);
        } else {
            this.hideClassWithAnimation(activationCbFunds);
            this.hideClassWithAnimation(activationCoreFunds);
        }
    }

    handleTransitionEnd(element) {
        element.addEventListener('transitionend', () => {
            if (!element.classList.contains('showing')) {
                element.classList.add('hidden');
            }
        }, {once: true});
    }

    showElement(element) {
        element.classList.remove('hidden', 'hiding');
        element.classList.add('showing');
    }

    hideElement(element, initial_animation_triggered) {
        this.transitionElementVisibility(element);

        if (!initial_animation_triggered) {
            // Dispatch transitionend event manually which will handle the hiding of the element initially
            this.dispatchTransitionEvent(element);
        }
    }

    toggleCommissionDependantElements(elements, hasCommission) {
        elements.forEach(element => {
            this.handleTransitionEnd(element);

            if (hasCommission) {
                this.showElement(element);
            } else {
                this.hideElement(element, this.initial_animation_triggered);
            }
        });

        this.initial_animation_triggered = true;
    }


    transitionElementVisibility(element) {
        if (element.classList.contains('showing')) {
            element.classList.remove('showing');
            element.classList.remove('hidden');
            element.classList.add('hiding');
        }
    }

    updateOutputs(commission, salary, poolPercentage) {
        this.commission_percentage_outputTarget.innerHTML = `${this.wageLevels[this.currentWageLevel].wageLevel.commission ?? 0}%`;
        this.commission_outputTarget.innerHTML = `$${SALARY_FORMATTER.format(commission.toFixed(2))}`;
        this.annual_salary_outputTarget.innerHTML = `$${SALARY_FORMATTER.format(salary.toFixed(2))}`;
        this.annual_and_commission_outputTarget.innerHTML = `$${SALARY_FORMATTER.format((salary + commission).toFixed(2))}`;
        this.accessible_commission_outputTarget.innerHTML = `${(poolPercentage * 100).toFixed(2)}%`;
    }

    reset() {
        ["core_funds_input", "capacity_building_input", "billable_hours_input"].forEach(field => {
            let target = this[`${field}Target`];
            target.value = target.dataset.defaultValue;
            target.dispatchEvent(new Event("change"));
            target.dispatchEvent(new Event("input"));
        });

        this.currentWageLevel = this.initialWageLevel;
        this.onWageLevelChange();
    }

    changeWageLevel(direction) {
        return this.adjustWageLevel(direction);
    }

    adjustWageLevel(direction) {
        const isNext = direction > 0;
        const limit = isNext ? this.wageLevelLimits.end : this.wageLevelLimits.start;
        if (limit === this.currentWageLevel) return;

        this.changeWageLevelWithCache(direction, isNext);
    }

    async changeWageLevelWithCache(direction, isNext) {
        const levelKey = isNext ? "next" : "prev";
        const cachedLevelId = this.wageLevels[this.currentWageLevel][levelKey];

        if (cachedLevelId) {
            this.setCurrentWageLevelFromCache(cachedLevelId, isNext);
            return;
        }

        const response = await this.wageLevelRequest(direction);
        this.handleWageLevelResponse(response, isNext);
    }

    setCurrentWageLevelFromCache(levelId, isNext) {
        const wageLevel = this.wageLevels[levelId].wageLevel;

        if(isNext){
            this.addWageLevelToCache(wageLevel, this.currentWageLevel, wageLevel.next);
        } else {
            this.addWageLevelToCache(wageLevel, wageLevel.prev, this.currentWageLevel);
        }

        this.currentWageLevel = wageLevel.id;
        this.onWageLevelChange();
    }


    handleWageLevelResponse(response, isNext) {

        if (!response.wage_level) {
            if (isNext) {
                this.wageLevelLimits.end = this.currentWageLevel;
            } else {
                this.wageLevelLimits.start = this.currentWageLevel;
            }
        } else {
            this.addWageLevelToCache(response.wage_level, isNext ? this.currentWageLevel : undefined, isNext ? undefined : this.currentWageLevel);
            this.currentWageLevel = response.wage_level.id;
            this.onWageLevelChange();
        }
    }

    wageLevelRequest(step = 0) {
        const params = step === 0 ? "" : `?step=${step}&current_level=${this.currentWageLevel}`;
        return getRequest(`${WAGE_LEVEL_API}${params}`);
    }

    salaryAndCommissionRequest() {
        const params = `wage_level=${this.currentWageLevel}&daily_billables=${this.billable_hours_inputTarget.value}&core_funding=${this.core_funds_inputTarget.value}&capacity_building=${this.capacity_building_inputTarget.value}`;
        return getRequest(`${COMMISSION_CALCULATION_API}?${params}`);
    }

    onWageLevelChange() {
        const wageLevel = this.wageLevels[this.currentWageLevel].wageLevel;
        this.wage_level_labelTarget.innerHTML = wageLevel.title;
        this.awaiting_request = false;
        this.onParameterChange();
    }

    addWageLevelToCache(wageLevel, prev = null, next = null) {
        if (this.wageLevels[wageLevel.id]) { // If it already exists
            this.wageLevels[wageLevel.id].prev = this.wageLevels[wageLevel.id].prev || prev;
            this.wageLevels[wageLevel.id].next = this.wageLevels[wageLevel.id].next || next;
        } else {
            this.wageLevels[wageLevel.id] = { wageLevel, prev, next };
        }
    }

    /**
     * Change to the next wage level
     * @returns {Promise<void>}
     */
    async nextWageLevel() {
        return this.changeWageLevel(1);
    }

    /**
     * Change to the previous wage level
     * @returns {Promise<void>}
     */
    async previousWageLevel() {
        return this.changeWageLevel(-1);
    }

    onParameterChange() {
        const billableHours = this.billable_hours_inputTarget.value;
        const coreFunds = this.core_funds_inputTarget.value;
        const capacityBuilding = this.capacity_building_inputTarget.value;

        if (this.awaiting_request) {
            this.current_request_stack.push({
                billableHours,
                coreFunds,
                capacityBuilding,
                wageLevel: this.currentWageLevel
            });
            return;
        }

        this.salaryAndCommissionRequest().then(response => {
            this.awaiting_request = false;
            this.updateEstimates(response.estimated_commission, response.salary, response.pool_percentage, response.position);
            if (this.current_request_stack.length > 0) {
                const lastRequest = this.current_request_stack.pop();
                this.current_request_stack = [];
                this.billable_hours_inputTarget.value = lastRequest.billableHours;
                this.core_funds_inputTarget.value = lastRequest.coreFunds;
                this.onParameterChange();
            }
        });
    }

    showClassWithAnimation(element) {
        element.classList.remove('hiding');
        element.classList.remove('hidden');
        element.classList.add('showing');

        this.dispatchTransitionEvent(element)
    }

    hideClassWithAnimation(element) {
        element.classList.remove('showing');
        element.classList.add('hiding');
        this.dispatchTransitionEvent(element)
    }

    dispatchTransitionEvent(element) {
        this.handleTransitionEnd(element);
        const event = new Event('transitionend');
        element.dispatchEvent(event);
    }


}

