export const DEFAULT_ZOOM_LEVEL = 14;

/**
 * Utility function to show an element
 * @param {string} elementId ID of the element to show
 */
export const showElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) element.style.display = 'block';
};

/**
 * Utility function to hide an element
 * @param {string} elementId ID of the element to hide
 */
export const hideElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) element.style.display = 'none';
};

/**
 * Utility function to clear and hide an element
 * @param {string} elementId ID of the element to clear and hide
 */
export const clearAndHideElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.innerHTML = '';
        element.style.display = 'none';
    }
};

export const capitalize = (string) => {
    return string
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}