import { Controller } from "stimulus";

const ACTIVE_CLASSES = ["bg-ascendPurple-dark", "text-white"];

const TOGGLE_YES = "yes"
const TOGGLE_NO = "no"


export default class extends Controller {
    static targets = ["yesView", "noView", "yesButton", "noButton", "selection"];

    /**
     * Connects the controller and checks if the selection is already set.
     * If the selection is "yes", it handles the "Yes" selection.
     * If the selection is "no", it handles the "No" selection.
     */
    connect() {
        // Check if the selection is already set
        if (this.selectionTarget.value === TOGGLE_YES) {
            this.handleYesSelection();
        } else if (this.selectionTarget.value === TOGGLE_NO) {
            this.handleNoSelection();
        }
    }

    /**
     * Handles the selection of the "Yes" option.
     * This function updates the visibility of the views and the active classes on the buttons.
     * It also triggers a click event on a specified button.
     */
    handleYesSelection() {
        // Toggle the view visibility
        this.yesViewTarget.classList.remove("hidden");
        this.noViewTarget.classList.add("hidden");

        // Toggle the classes on the buttons
        this.noButtonTarget.classList.remove(...ACTIVE_CLASSES);
        this.yesButtonTarget.classList.add(...ACTIVE_CLASSES);

        // Trigger a click event on the button with the id stored in the data-click-id attribute
        // Only toggle if the value has changed
        if (this.selectionTarget.value !== TOGGLE_YES) this.triggerClickEvent();

        // Set the selection value to "yes"
        this.selectionTarget.value = TOGGLE_YES;
    }

    /**
     * Handles the selection of the "No" option.
     * This function updates the visibility of the views and the active classes on the buttons.
     * It also removes elements with the class name 'yesNoDelete' if present.
     */
    handleNoSelection() {
        // Hide the "Yes" view and show the "No" view
        this.yesViewTarget.classList.add("hidden");
        this.noViewTarget.classList.remove("hidden");

        // Update the button classes to reflect the "No" selection
        this.noButtonTarget.classList.add(...ACTIVE_CLASSES);
        this.yesButtonTarget.classList.remove(...ACTIVE_CLASSES);

        // Remove elements with the class name 'yesNoDelete' if present
        // Only toggle if value has changed
        if (this.selectionTarget.value !== TOGGLE_NO) this.removeYesNoElements();

        // Set the selection value to "no"
        this.selectionTarget.value = TOGGLE_NO;
    }

    /**
     * Removes elements with the class name 'yesNoDelete' within the 'nested-fields' class.
     * This function continuously checks for the presence of such elements and removes them
     * until no more elements are found.
     */
    removeYesNoElements() {
        this.element.querySelectorAll(".nested-fields[name='yesNoDelete']")
            .forEach((element) => element.remove());
    }

    /**
     * Triggers a click event on the button with the id stored in the data-click-id attribute.
     * This function retrieves the id from the element's dataset and clicks the corresponding button.
     */
    triggerClickEvent() {
        let clickTargetId = this.element.dataset.clickId;
        document.getElementById(clickTargetId)?.click();
    }
}