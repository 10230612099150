import {Controller} from "stimulus";

/**
 * This controller is responsible for toggling values between days and hours for the leave balances
 * ASSUMPTIONS:
 *  - The state of the values is stored in the data-is-days attribute (1 == days, 0 == hours)
 *  - Labels are controlled by the data attrbitues on the toggle:
 *      - labelClass = target label
 *      - labelHours and labelDays = labels that we'll use to replace when the toggle is toggled
 *  - On state = days
 *  - The state will apply to all values in the controller target (not individual values)
 *  - 1 Day = 7.6 hours (FTE)
 */

const HOURS_IN_DAY = 7.6;

const SLIDER_BG = {
    on: "bg-ascendPurple-dark",
    off: "bg-gray-200"
}

const SLIDER_TRANSLATION = {
    on: "translate-x-6",
    off: "",
}

export default class extends Controller {

    static targets = ["values", "toggle"];

    connect() {
        this.labelClass = null;

        this.labelHours = this.toggleTarget.dataset.labelHours ?? "Hours";
        this.labelDays = this.toggleTarget.dataset.labelDays ?? "Days";

        if (this.toggleTarget.dataset.labelClass !== undefined) {
            this.labelClass = this.toggleTarget.dataset.labelClass;
        }


        this.toggle(null,true);
    }


    toggle(event, firstRun = false) {

        // This will default to hours if the attribute is not set
        const isDays = this.toggleTarget.dataset.isDays === "1";


        // Toggle the state (or add it when empty)
        this.toggleTarget.dataset.isDays = isDays ? "0" : "1";


        // Toggle the values
        this.valuesTargets.forEach((element) => {
            const value = element.innerText;
            if (isDays) {
                if (!firstRun) {
                    element.innerText = this.toDays(value);
                }
                this.toggleSliderOn();
            } else {
                if (!firstRun) {
                    element.innerText = this.toHours(value);
                }
                this.toggleSliderOff();
            }
        });
    }

    toggleSliderOn() {
        // Get the dot and slider element from the toggle target
        const dot = this.toggleTarget.querySelector(".dot");
        const slider = this.toggleTarget.querySelector(".slider");

        // Give the slider a nice animation by applying
        dot.classList.add(SLIDER_TRANSLATION.on);
        slider.classList.remove(SLIDER_BG.off);
        slider.classList.add(SLIDER_BG.on);

        if (this.labelClass) {
            const labelClass = this.labelClass;
            this.valuesTargets.forEach((element) => {
                const currentLabel = element.parentElement.querySelector(`.${labelClass}`);

                // Regex ignores the case
                currentLabel.innerHTML = currentLabel.innerHTML.replace(new RegExp(this.labelHours, 'i'), this.labelDays);
            })
        }
    }

    toggleSliderOff() {

        // Get the dot and slider element from the toggle target
        const dot = this.toggleTarget.querySelector(".dot");
        const slider = this.toggleTarget.querySelector(".slider");

        // Give the slider a nice animation by applying
        dot.classList.remove(SLIDER_TRANSLATION.on);
        dot.classList.remove(SLIDER_TRANSLATION.on);
        slider.classList.add(SLIDER_BG.off);


        if (this.labelClass) {
            const labelClass = this.labelClass;
            this.valuesTargets.forEach((element) => {
                const currentLabel = element.parentElement.querySelector(`.${labelClass}`);
                currentLabel.innerHTML = currentLabel.innerHTML.replace(new RegExp(this.labelDays, 'i'), this.labelHours);
            })
        }
    }

    toHours(value) {
        return (value * HOURS_IN_DAY).toFixed(1);
    }

    toDays(value) {
        return (value / HOURS_IN_DAY).toFixed(1);
    }
}
