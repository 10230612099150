import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'copy', 'paste' ]

  connect() {
  }

  copy() {
    this.pasteTarget.value = this.pasteTarget.value + this.copyTarget.textContent
  }
}
