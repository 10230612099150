import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checked", "destroy" ]

  connect() {
    this.select()
  }

  select() {
    const checked = this.checkedTarget.checked
    this.destroyTarget.checked = !checked
  }
}