/**
 * Loads the React components required for the application asynchronously.
 *
 * @returns {Promise<Awaited<{}>[]>} - A promise that resolves to an array of imported components.
 */
export async function loadComponents() {
    try {
        const HazardApp = import('./HazardReporting/pages/HazardApp')
            .then(({ renderHazardApp }) => renderHazardApp());
        const TeamApp = import('./TeamApp/pages/TeamApp')
            .then(({ renderTeamApp }) => renderTeamApp());
        const ICFApp = import('./ICFCarePlan/pages/ICFApp')
            .then(({ renderICFApp }) => renderICFApp());
        const IcfDetailsApp = import('./ICFCarePlan/Details/IcfDetailsApp')
            .then(({ renderIcfDetailsApp }) => renderIcfDetailsApp());

        return Promise.all([HazardApp, TeamApp, ICFApp, IcfDetailsApp]);
    } catch (error) {
        console.error('Error loading components:', error);
    }
}