import {Controller} from "stimulus"
import ConfettiGenerator from "confetti-js";

const CONFETTI_SETTINGS = {
    "max": "200",
    "size": "1.2",
    "animate": true,
    "props": ["circle", "square", "triangle", "line"],
    "colors": [[165, 104, 246], [230, 61, 135], [0, 199, 228], [253, 214, 126]],
    "clock": "75",
    "rotate": true,
    "start_from_edge": true,
    "respawn": false
}

export default class extends Controller {
    static targets = ["confetti"]

    connect() {

        // Wait half a second to show the confetti
        setTimeout(() => {
            this.showConfetti()
        }, 500)

    }

    showConfetti() {
        if (this.confettiTarget) {

            const confettiSettings = {...CONFETTI_SETTINGS, target: this.confettiTarget}
            const confetti = new ConfettiGenerator(confettiSettings)
            confetti.render()
        }
    }
}