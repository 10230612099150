import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["menu"];

    /**
     * Connect the event listeners
     */
    connect() {
        this.element.addEventListener('blur', this.hideOnBlur.bind(this), true);
        this.menuTarget.addEventListener('click', this.hideOnClick.bind(this), true);
    }

    /**
     * Disconnect the event listeners
     */
    disconnect() {
        this.element.removeEventListener('blur', this.hideOnBlur.bind(this), true);
        this.menuTarget.removeEventListener('click', this.hideOnClick.bind(this), true);
    }

    /**
     * Toggles the hidden class on the menu
     */
    toggle() {
        this.menuTarget.classList.toggle("hidden");
    }

    /**
     * Hides the menu when a blur event is triggered
     */
    hideOnBlur() {
        // Check if the related target is not inside the menu
        if (!this.menuTarget.contains(event.relatedTarget)) {
            this.menuTarget.classList.add("hidden");
        }
    }

    /**
     * Hides the menu when a click is triggered inside the menu item
     * This will allow additional functionality to be added to the menu items while waiting for an
     * async response from external controller
     */
    hideOnClick() {
        if (this.menuTarget.contains(event.relatedTarget)) {
            this.menuTarget.classList.add("hidden");
        }
    }
}