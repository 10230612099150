import { getMetaValue } from "./index";

/**
 * A map to store AbortController instances associated with specific keys.
 * @type {Map<string, AbortController>}
 */
const abortMaps = new Map();

/**
 * Makes a GET request to the specified URL.
 * Optionally, an abort key can be provided to allow aborting the request.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {string} [abortKey=null] - An optional key to associate with the AbortController. This allows requests to be aborted by key.
 * @returns {Promise<Object>} - A promise that resolves to the response data in JSON format.
 */
export const getRequest = async (url, abortKey = null) => {
    let signal = null;

    if (abortKey) signal = createAbortKey(abortKey);

    const response = await fetch(url, {
        signal,
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": getMetaValue("csrf-token"),
        },
    });

    return response.json();
}

/**
 * Makes a POST request to the specified URL with the provided data.
 * Optionally, an abort key can be provided to allow aborting the request.
 *
 * @param {string} url - The URL to send the POST request to.
 * @param {Object} data - The data to be sent in the body of the POST request.
 * @param {string} [abortKey=null] - An optional key to associate with the AbortController.
 * @returns {Promise<Response>} - A promise that resolves to the response object.
 */
export const postRequest = async (url, data, abortKey = null) => {

    let signal = null;
    if (abortKey) signal = createAbortKey(abortKey);

    return await fetch(url, {
        signal,
        method: "POST",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        body: JSON.stringify(data)
    });
}

/**
 * Creates an AbortController for the given key.
 * If an AbortController already exists for the key, it aborts the previous request.
 *
 * @param {string} abortKey - The key to associate with the AbortController.
 * @returns {AbortSignal} - The signal from the new AbortController.
 */
const createAbortKey = (abortKey) => {
    if (abortMaps.has(abortKey)) {
        abortMaps.get(abortKey).abort("Request aborted by new request.");
    }

    const controller = new AbortController();
    abortMaps.set(abortKey, controller);

    return controller.signal;
}