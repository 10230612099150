import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "billableItems", "quantity", "unitPrice", 'subtotal' ]

  connect() {
    // Listen for the custom event to recalculate the total when an association is removed
    const removeEventName = this.data.get("removeEventName")
    document.addEventListener(removeEventName, this.calculateInvoiceTotal.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener(removeEventName, this.calculateInvoiceTotal.bind(this));
  }

  calculateItemTotal() {
    const billableItemsPriceStr = this.billableItemsTarget.selectedOptions[0]?.dataset["price"]
    const billableItemsPriceFloat = parseFloat((billableItemsPriceStr ||0) / 100).toFixed(2) 
    const quantity = this.quantityTarget.value || 0
    const subtotal = parseFloat(billableItemsPriceFloat * quantity).toFixed(2)
    this.unitPriceTarget.value = billableItemsPriceFloat
    this.subtotalTarget.innerHTML = `AUD ${subtotal}`
    this.subtotalTarget.value = subtotal
    // re-calculate Invoice Total
    this.calculateInvoiceTotal();
  }

  calculateInvoiceTotal() {
    let invoiceTotal = 0
    document.getElementsByName('subtotal').forEach(element => {
      invoiceTotal = invoiceTotal + parseFloat(element.value || 0)
    });
    
    document.getElementById('invoice-total').innerHTML = `AUD ${invoiceTotal.toFixed(2)}`;
  }
}
