import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["output", "add"]

    append(event) {
        const file = event.target.files[0];
        const {fileType, fileName} = this.getFileDetails(file);


        // Shorten the file name
        // Not the best way to handle this
        let pattern = /[^\/]+$/;
        let extension = pattern.exec(fileType)[0];
        let shortFilename = fileName.replace(`.${extension}`, "").substring(0, 20).concat(`.${extension}`);

        this.outputTarget.textContent = `${shortFilename} (Click "Save" to upload this document)`;
        this.outputTarget.classList.add("text-green-500");
        this.addTarget.classList.remove("hidden");
    }

    getFileDetails(file) {
        return {
            fileType: file.type,
            fileName: file.name,
        }
    }


}