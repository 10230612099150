import {Controller} from "stimulus";
import Choices from "choices.js";

import "choices.js/public/assets/styles/choices.css";
import "../stylesheets/choices";

/**
 * MultiselectController class handles the initialization and configuration
 * of the Choices.js multiselect component within a Stimulus controller.
 *
 * To define the placeholder text for the multiselect, add a `data-placeholder` to the select element.
 */
export default class extends Controller {

    static targets = ["select"];

    multiselect = null;

    /**
     * Connects the controller to the DOM and initializes the Choices.js multiselect.
     *
     * @returns {void}
     */
    connect() {
        this.multiselect = new Choices(this.selectTarget, {
            removeItemButton: true,
            placeholder: true,
            placeholderValue: this.selectTarget.getAttribute('data-placeholder') ?? "Select an option",
        });
    }
}