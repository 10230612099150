import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["input", "hiddenInput"]

    connect() {
        console.debug("CodeEntryController connected")
        this.inputTargets.forEach((input, index) => {
            console.debug("input", input, index)
            input.addEventListener('input', () => this.handleInput(index))
            input.addEventListener('keydown', (e) => this.handleKeydown(e, index))
            input.addEventListener('paste', (e) => this.handlePaste(e, index))
        })
    }

    handleInput(index) {
        console.debug("handleInput", index)
        if (this.inputTargets[index].value.length === 1 && index < this.inputTargets.length - 1) {
            this.inputTargets[index + 1].focus()
        }
        this.updateHiddenInput()
    }

    handleKeydown(e, index) {
        if (e.key === 'Backspace' && index > 0 && this.inputTargets[index].value === '') {
            this.inputTargets[index - 1].focus()
        }
        this.updateHiddenInput()
    }

    handlePaste(e, index) {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        const pasteArray = pasteData.split('');

        pasteArray.forEach((char, i) => {
            if (index + i < this.inputTargets.length) {
                this.inputTargets[index + i].value = char;
            }
        });

        // Focus the last input where the paste ends or the next available input
        const nextIndex = Math.min(index + pasteArray.length, this.inputTargets.length - 1);
        this.inputTargets[nextIndex].focus();

        this.updateHiddenInput();
    }

    updateHiddenInput() {
        const code = this.inputTargets.map(input => input.value).join('')
        this.hiddenInputTarget.value = code
    }
}