import { Controller } from "stimulus"

/**
 * FlatpickrStartEndController is a controller that manages the syncing between start and end date pickers.
 */
export default class extends Controller {
    static targets = ["start", "end"]

    connect() {
    }

    /**
     * Updates the minimum date of the end date picker when the start date changes.
     *
     * @param {Event} event - The event object representing the change event.
     */
    onStartDateChange(event) {
        this.endTarget._flatpickr.set("minDate", event.target.value)
    }

    /**
     * Updates the maximum date of the start date picker when the end date changes.
     *
     * @param {Event} event - The event object representing the change event.
     */
    onEndDateChange(event) {
        this.startTarget._flatpickr.set("maxDate", event.target.value)
    }
}