/**
 * SessionService class handles API requests related to session expiry and refresh.
 * This is the first use of a service in JS so just for reference, extract the logic from the component to the service
 * allows for better separation of concerns and easier testing.
 */
export default class SessionService {
    /**
     * Constructs the SessionService with the provided API object.
     * @param {Object} api - An object containing methods for making API requests.
     */
    constructor(api) {
        this.api = api;
    }

    /**
     * Fetches the session expiry time from the server.
     * @returns {Promise} A promise that resolves with the server response.
     */
    getExpiry() {
        return this.api.getRequest("/api/v2/providers/session_expiry");
    }

    /**
     * Sends a request to refresh the session.
     * @returns {Promise} A promise that resolves with the server response.
     */
    refreshSession() {
        return this.api.postRequest("/api/v2/providers/refresh_session");
    }
}