import { Controller } from "stimulus"

// NOT IN USE???
export default class extends Controller {
  static targets = [ "service", "duration" ];

  connect() {
  }

  handleSelect() {
    let url = this.data.get("url")
    if (this.serviceTarget.value) {
      let fullUrl = `${url}/${this.serviceTarget.value}`

      fetch(fullUrl, {
        method: 'GET',
        credentials: 'include',
        dataType: 'script',
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token"),
          "Content-Type": "application/json"
        },
      })
      .then(response => response.json())
      .then(data => {
        this.durationTarget.textContent = data["minimum_session_time"]
      })
    }
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}