import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "hamburger" ]

  connect() {
  }

  toggle() {
    this.data.set("state", 1 - this.data.get("state"))
    if (this.data.get("state") == 0) {
      this.menuTarget.classList.remove('block')
      this.menuTarget.classList.add('hidden')
      this.hamburgerTarget.classList.remove('active')
    } else {
      this.menuTarget.classList.remove('hidden')
      this.menuTarget.classList.add('block')
      this.hamburgerTarget.classList.add('active')
    }
  }
}
