import {Controller} from "stimulus";
import {getRequest} from "../helpers/api";

const WORK_STATUS_KEY = "work_status_next_check"

export default class extends Controller {
    static targets = ["modal"];

    next_check = null;

    connect() {
        if (this.isNextCheckInFuture()) return;

        getRequest("/api/v2/providers/work_status_update_required").then(data => {
            this.next_check = data.next_check;
            if (data.update_required) {
                this.open(); // Open the modal and handle the local storage update on submit
            } else {
                // Update local storage with the next check time
                localStorage.setItem(WORK_STATUS_KEY, this.next_check);
                this.next_check = null;
            }
        });
    }

    /**
     * Checks if the next check time stored in local storage is in the future.
     *
     * This method retrieves the `work_status_next_check` value from local storage,
     * converts it to a Date object, and compares it with the current date and time.
     *
     * @returns {boolean} True if the next check time is in the future, false otherwise.
     */
    isNextCheckInFuture() {
        const nextCheck = localStorage.getItem(WORK_STATUS_KEY);
        if (nextCheck) {
            const nextCheckDate = new Date(nextCheck);
            return nextCheckDate > new Date();
        }
        return false;
    }

    open() {
        this.modalTarget.classList.remove("hidden");
    }

    /**
     * Updates the next check time in local storage.
     *
     * This method checks if `next_check` is set. If it is, it updates the local storage
     * with the value of `next_check` and then resets `next_check` to null.
     *
     * @returns {void}
     */
    update_next_check() {
        if (!this.next_check) return;

        // Update local storage with the next check time
        localStorage.setItem(WORK_STATUS_KEY, this.next_check);
        this.next_check = null;
    }
}
