import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"];

  connect() {
    this.toggle()
  }

  toggle() {
    let sharedClass='cursor-pointer flex items-center justify-center rounded-md py-1 px-1 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none'
    // let color = 'ascendPurple-dark'
    this.optionTargets.map(option => {
      if (option.firstElementChild.checked) {
        let trueValue =  option.firstElementChild.value === 'true'
        // Boolean with value true - color green, woth value false - color red
        let color = trueValue ? 'green' : 'red'
        let classes = `${sharedClass} bg-${color}-500 text-white hover:bg-${color}-800 ring-2 ring-${color}-500 ring-offset-2`
        option.className = classes;
      } else {
        option.className = `${sharedClass} ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50`;
      }
    })
  }

  select(event) {
    // Event.target is label tag, input tag or span tag
    // Our goal is to find INPUT element and mark that as checked
    let tagName = event.target.tagName
    let labelTag = tagName == "LABEL" ? event.target : event.target.parentNode
    labelTag.firstElementChild.checked = true
  }
}
