import {Controller} from "stimulus";

const DISMISS_BANNER_STORAGE_KEY = "dismiss_banner"

export default class extends Controller {

    static targets = ["banner", "alternativePrompt", "dismiss"]

    connect() {
        // Handle if no targets are found
        if (this.hasBannerTarget === false) {
            return;
        }

        // By default, all banners are hidden. Check the session variable and see if we need to show any banners
        this.showBanner();
    }

    showBanner() {
        const dismissed = localStorage.getItem(DISMISS_BANNER_STORAGE_KEY);
        // Check if the banner is already dismissed
        if (dismissed === "true") {
            this.bannerTarget.classList.add("hidden")
            this.alternativePromptTarget.classList.remove("hidden")
            this.shrinkAlternativePrompt();
        } else {
            this.bannerTarget.classList.remove("hidden")
            this.alternativePromptTarget.classList.add("hidden")
        }
    }

    dismissBanner() {
        localStorage.setItem(DISMISS_BANNER_STORAGE_KEY, "true");
        this.bannerTarget.classList.add("hidden")
        this.alternativePromptTarget.classList.remove("hidden")
        this.shrinkAlternativePrompt();
    }

    shrinkAlternativePrompt() {
        setTimeout(() => {
            this.alternativePromptTarget.classList.add("shrink-more-work");
        }, 5000);
    }
}