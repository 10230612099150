import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["option", "button", "unhide"];
  
  connect() {
    this.optionTargets.forEach((option) => {
      if (option.checked === true) {
        let targetElement = this.buttonTargets.find(
          (el) => el.id === option.value
        );
        targetElement.classList = ["block"];
      }
    });
  }
  selectRadioOption(e) {
    this.buttonTargets.forEach((targetElement) => {
      if (e.target.value === targetElement.id) {
        targetElement.classList.remove("hidden");
        
        let enabled = document.getElementsByClassName(e.target.dataset.enablefield)
        if (enabled.length > 0) {
          for (let i = 0; i < enabled.length; i++) {
            enabled[i].removeAttribute('disabled');
          }
        }

      } else {
        targetElement.classList.add("hidden");

        let disabled = document.getElementsByClassName(e.target.dataset.disablefield)
        if (disabled.length > 0) {
          for (let i = 0; i < disabled.length; i++) {
            disabled[i].setAttribute('disabled', 'true');
          }
        }
      }
    });

  }

  highlightRadioOption(e) {
    this.optionTargets.forEach((targetElement) => {
      if (e.target.value === targetElement.value) {
        targetElement.classList = ["selectedBtn"];
      } else {
        targetElement.classList = ["unselectedBtn"];
      }
    });
  }
  unhideOptions() {
    this.unhideTarget.classList.remove("hidden");
  }
}
