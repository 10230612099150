import {applyFilters, focusOnMarker, getMemberMarker, getProviderMarker} from "./marker_management";
import selectionManager from "./selection_manager";
import {clearAndHideElement, hideElement, showElement} from "./utilities";
import {debounce} from "chart.js/helpers";
import {resetDirectionsButton} from "./directions_management";

export const MEMBER_LIST_ID = "member-list";
export const PROVIDER_LIST_ID = "provider-list";
export const MEMBER_SEARCH_CONTAINER_ID = "member-search-container";
export const PROVIDER_SEARCH_CONTAINER_ID = "provider-search-container";
export const MEMBER_BACK_BUTTON_ID = "member-back-button";
export const PROVIDER_BACK_BUTTON_ID = "provider-back-button";

export const MEMBER_SELECT_BUTTON_CLASS = ".member-map-select-button"
export const PROVIDER_SELECT_BUTTON_CLASS = ".provider-map-select-button"
export const MEMBER_FOCUS_BUTTON_CLASS = ".member-focus-button"
export const PROVIDER_FOCUS_BUTTON_CLASS = ".provider-focus-button"

export const MEMBER_SEARCH_ID = "member-search";
export const PROVIDER_SEARCH_ID = "provider-search";


export const hideMemberSearch = () => {
    hideElement(MEMBER_LIST_ID);
    hideElement(MEMBER_SEARCH_CONTAINER_ID);
    showElement(MEMBER_BACK_BUTTON_ID);
};

export const showMemberSearch = () => {
    showElement(MEMBER_LIST_ID);
    showElement(MEMBER_SEARCH_CONTAINER_ID);
    hideElement(MEMBER_BACK_BUTTON_ID);
};

export const hideMemberDetails = () => {
    clearAndHideElement("selected-member-container");
};

export const showMemberDetails = () => {
    showElement("selected-member-container");
};

export const hideProviderSearch = () => {
    hideElement(PROVIDER_LIST_ID);
    hideElement(PROVIDER_SEARCH_CONTAINER_ID);
    showElement(PROVIDER_BACK_BUTTON_ID);
};

export const showProviderSearch = () => {
    showElement(PROVIDER_LIST_ID);
    showElement(PROVIDER_SEARCH_CONTAINER_ID);
    hideElement(PROVIDER_BACK_BUTTON_ID);
};

export const hideProviderDetails = () => {
    clearAndHideElement("selected-provider-container");
};

export const showProviderDetails = () => {
    showElement("selected-provider-container");
};

/**
 * Shows the loading bar.
 */
export const showLoadingBar = () => {
    const loadingBar = document.getElementById("loadingBar");
    loadingBar.classList.remove("hidden");
    loadingBar.classList.add("w-0");
    loadingBar.classList.add("animate-loading");
};

/**
 * Hides the loading bar.
 */
export const hideLoadingBar = () => {
    document.getElementById("loadingBar").classList.add("hidden");
};

/**
 * Handles click events on member select buttons.
 * @param {Event} event - The click event.
 */
export const onMemberSelectButton = (event) => {
    const memberId = event.target.getAttribute('data-member-id');
    const memberMarker = getMemberMarker(memberId);
    if (!memberMarker) return;

    google.maps.event.trigger(memberMarker, 'click');
}

/**
 * Handles click events on member select buttons.
 * @param {Event} event - The click event.
 */
export const onProviderSelectButton = (event) => {
    const providerId = event.target.getAttribute('data-provider-id');
    const providerMarker = getProviderMarker(providerId);
    if (!providerMarker) return;

    google.maps.event.trigger(providerMarker, 'click');
}

/**
 * Handles click events on the provider focus button.
 * @param event - The click event.
 */
export const onProviderFocusButton = (event) => {
    const providerId = event.target.getAttribute('data-provider-id');
    const providerMarker = getProviderMarker(providerId);
    if (!providerMarker) return;

    focusOnMarker(providerMarker);
}

/**
 * Handles click events on the member focus button.
 * @param event The click event.
 */
export const onMemberFocusButton = (event) => {
    const memberId = event.target.getAttribute('data-member-id');
    console.debug("Member ID", memberId);
    const memberMarker = getMemberMarker(memberId);
    if (!memberMarker) return;

    focusOnMarker(memberMarker);
}

/**
 * Initializes the buttons and interactions on the list view
 */
export const initializeList = () => {
    document.addEventListener('click', (event) => {
        if (event.target.closest(MEMBER_SELECT_BUTTON_CLASS)) {
            onMemberSelectButton(event);
        } else if (event.target.closest(PROVIDER_SELECT_BUTTON_CLASS)) {
            onProviderSelectButton(event);
        } else if (event.target.closest(PROVIDER_FOCUS_BUTTON_CLASS)) {
            onProviderFocusButton(event);
        } else if (event.target.closest(MEMBER_FOCUS_BUTTON_CLASS)) {
            onMemberFocusButton(event);
        }
    });

    // Bind the back button
    document.getElementById(MEMBER_BACK_BUTTON_ID).addEventListener('click', () => {
        showMemberSearch();
        hideMemberDetails();
        selectionManager.setSelectedMember(null);
        resetDirectionsButton();
    });

    // Bind the back button
    document.getElementById(PROVIDER_BACK_BUTTON_ID).addEventListener('click', () => {
        showProviderSearch();
        hideProviderDetails();
        selectionManager.setSelectedProvider(null);
        resetDirectionsButton();
    });

    document.getElementById('member-search').addEventListener('input', debounce(async () => {
        await applyFilters();
    }, 500)); // Adjust debounce time as needed

    document.getElementById('provider-search').addEventListener('input', debounce(async () => {
        await applyFilters();
    }, 500)); // Adjust debounce time as needed
}

export const getCurrentMemberFilter = () => {
    return document.getElementById(MEMBER_SEARCH_ID).value;
}

export const getCurrentProviderFilter = () => {
    return document.getElementById(PROVIDER_SEARCH_ID).value;
}
