import {getMapParams} from "./get_map_params";
import {fetchAndUpdateMarkers, initializeMarkers} from "./marker_management";
import {initializeDirectionsButton} from "./directions_management";
import {mapManager} from "./map_manager";
import {initializeList} from "./interactions";
import {initialiseMapSearch} from "./map_filters";

const MAP_CONTAINER_ID = "matchmaking-map";
const MAP_ID = "bf034a490c4e1539"; // Map for styling purpose, as defined in Google Console


/**
 * Initializes the map and its controls.
 */
const initializeMatchmakingMap = async () => {

    const mapElement = document.getElementById(MAP_CONTAINER_ID);
    if (!mapElement) return;

    await loadGoogleMapsPlaces();

    const {latitude, longitude} = getMapParams();
    const position = {lat: latitude, lng: longitude};

    const {Map} = await google.maps.importLibrary("maps");
    const newMap = new Map(mapElement, {
        center: position,
        zoom: 16,
        mapId: MAP_ID,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
    });
    mapManager.setMap(newMap);

    // Create the div to hold the custom control
    const refreshControlDiv = document.createElement('div');
    const refreshControl = createRefreshButton();
    refreshControlDiv.appendChild(refreshControl);
    newMap.controls[google.maps.ControlPosition.TOP_CENTER].push(refreshControlDiv);

    // initMapControls();
    await initializeMarkers();
    await initializeDirectionsButton();
    initialiseMapSearch();

    initializeList();
};

/**
 * Ensures the Google Maps Places library is loaded.
 */
const loadGoogleMapsPlaces = async () => {
    if (!google.maps.places) {
        await google.maps.importLibrary("places");
    }
}

const createRefreshButton = () => {
    const button = document.createElement("button");
    button.classList.add("m-3", "bg-ascendPurple-dark", "text-white", "text-lg", "flex", "items-center", "font-semibold", "py-2", "px-4", "rounded-md", "shadow", "z-10");
    button.innerHTML = "Search area <i class='fas fa-rotate ml-2'></i>";
    button.addEventListener("click", async () => {
        await fetchAndUpdateMarkers();
    });
    return button;
}




export default initializeMatchmakingMap;