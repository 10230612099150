import {mapManager} from "./map_manager";


/**
 * Initialises the map search functionality with Google Places Autocomplete.
 * This function specifically restricts the autocomplete search to Australia,
 * and upon selecting a place, it updates the map's center and zoom level to the selected location.
 */
export const initialiseMapSearch = () => {
    const input = document.getElementById('suburb-search');
    const options = {
        componentRestrictions: { country: "AU" },
        fields: ['address_components', 'geometry']
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
        }
        const coords = place.geometry.location;
        mapManager.getMap().setCenter(coords);
        mapManager.getMap().setZoom(14);
    });
};

