import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "mainCategory", "subcategory", "activity", "billable" ]

  connect() {
    let activityCode = ''
    this.billableTargets.forEach(el => {
      const checkboxx = el.children[0];
      if (checkboxx.checked) {
        if ( el.dataset.ancestry === activityCode) return
        activityCode =  el.dataset.ancestry
        this.checkActivities(true, '-', el.dataset.ancestry)
        this.checkSubcategories(true, '-', el.dataset.ancestry)
        this.checkMainCategories(el.dataset.ancestry)
      }
    })
  }

  select(event) {
    const checked = event.target.checked
    let ancestors = event.target.value
    if (event.target.dataset.ancestry) ancestors = `${event.target.dataset.ancestry}/${ancestors}`
    
    this.checkSubcategories(checked, ancestors)
    this.checkActivities(checked, ancestors)
    this.checkBillableItems(checked, ancestors)
  }

  checkMainCategories(children) {
    this.mainCategoryTargets.forEach((el) => {
      const checkbox = el;
      if (children.includes(`${el.value}/`)) {
        checkbox.checked = true;
      }
    });
  }

  checkSubcategories(checked, ancestry, children = '') {
    this.subcategoryTargets.forEach((el) => {
      const checkbox = el;
      const checkboxAncestor = checkbox.dataset.ancestry
      if (checkboxAncestor.includes(ancestry) || children.includes(`/${el.value}/`)) {
        checkbox.checked = checked;
      }
    });
  }

  checkActivities(checked, ancestry, children = '') {
    this.activityTargets.forEach((el) => {
      const checkbox = el;
      const checkboxAncestor = checkbox.dataset.ancestry
      if (checkboxAncestor.includes(ancestry) || children.includes(`/${el.value}`)) {
        checkbox.checked = checked;
      }
    });
  }

  checkBillableItems(checked, ancestry) {
    this.billableTargets.forEach((el) => {
      const checkbox = el.children[0];
      const checkboxAncestor = el.dataset.ancestry

      if (checkboxAncestor.includes(ancestry)) {
        checkbox.checked = checked;
      }
    });
  }
}