import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["element", "select"]

  connect() {
    if (this.hasSelectTarget) {
      this.toggle(this.elementTarget, this.selectTarget.value);
    }
  }

  changed(event) {
    if (this.hasElementTarget) {
      this.toggle(this.elementTarget, event.target.value);
    }
  }

  toggle(element, value) {
    if (value == "") {
      element.hidden = true;
    }
    else {
      element.hidden = false;
    }
  }

}
