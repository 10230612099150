import { MEMBER_FOCUS_BUTTON_CLASS } from "./interactions";
import { capitalize } from "./utilities";
import { renderEntityAvatar } from "../../helpers/render_avatar";

const SELECTED_MEMBER_CONTAINER_ID = "selected-member-container";

const SERVICE_TYPE_STYLING = {
    "Both": "bg-amber-500 text-white",
    "Therapy": "bg-green-500",
    "Support Work": "bg-blue-500 text-white",
    "": "hidden"
};

/**
 * Renders the details of a selected member into the designated container.
 *
 * @param {Object} member - The member object containing details to render.
 */
export const renderMemberDetails = (member) => {
    const container = document.getElementById(SELECTED_MEMBER_CONTAINER_ID);
    if (!container || !member) return; // Don't render anything if container or member is not available
    container.innerHTML = memberDetailsTemplate(member);
};

/**
 * Generates the HTML template for the member details.
 *
 * @param {Object} member - The member object containing details to render.
 * @returns {string} The HTML string for the member details.
 */
const memberDetailsTemplate = (member) => {
    return `
<div class="selected-member shadow-md shadow-slate-400 rounded-lg overflow-hidden flex flex-col">
  <div class="px-4 pt-4 flex justify-between">
      ${renderEntityAvatar(member, {
        width: "3.5rem",
        fontSize: "1.5rem",
        backgroundColor: member.avatar_background_colour
    }).outerHTML}
      <div>
      <button class="${SERVICE_TYPE_STYLING[capitalize(member.type_of_service.replace('_', ' '))]} py-2 px-4 rounded-md">
        ${capitalize(member.type_of_service.replace('_', ' '))}
      </button>
    </div>
  </div>
  <div class="p-4">
    <div class="flex flex-row items-center justify-start">
      <button data-member-id="${member.id}" class="${MEMBER_FOCUS_BUTTON_CLASS.substring(1)} text-black opacity-50 hover:text-ascendPurple-dark transition p-1 -ms-1 hover:opacity-100">
        <i class="fas fa-location-crosshairs cursor-pointer pointer-events-none"></i>
      </button>
      <a href="/members/${member.id}">
        <h2 class="text-xl font-semibold hover:underline cursor-pointer">${member.first_name} ${member.last_name}</h2>
      </a>
    </div>
    <p class="text-gray-700">${member.full_address ?? "No address entered"}</p>
    <p class="text-gray-700">
      Availability: <br/>${member.availability ?? "No availability entered"}
    </p>
  </div>
</div>
    `;
};