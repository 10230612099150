import {Controller} from "stimulus";

const SALARY_FORMATTER = new Intl.NumberFormat('en-AU'); // Format the salary nicely

export default class extends Controller {

    static targets = ["input", "output"];

    connect() {
        this.inputTarget.addEventListener("input", this.updateOutput.bind(this));
    }

    updateOutput() {
        const prefix = this.outputTarget.dataset["prefix"] || "";

        if (prefix === "$") {
            this.outputTarget.innerHTML = `${prefix}${SALARY_FORMATTER.format(this.inputTarget.value)}`;
            return;
        }


        this.outputTarget.innerHTML = this.inputTarget.value;
    }

}