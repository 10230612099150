import { Controller } from "stimulus"
import { getRequest } from "../helpers/api";
import { debounce } from "../helpers/debounce";

/**
 * LeaveRequestHoursController is a Stimulus controller that handles the calculation
 * of leave request hours based on the start and end dates provided by the user.
 */
export default class extends Controller {

    static targets = ["start_date", "end_date", "hours"]

    /**
     * Called when the controller is connected to the DOM.
     * Binds event listeners to the start and end date input fields.
     */
    connect() {
        this.bind()
    }

    /**
     * Binds change event listeners to the start and end date input fields.
     */
    bind() {
        this.start_dateTarget.addEventListener("change", this.debouncedGetHours.bind(this))
        this.end_dateTarget.addEventListener("change", this.debouncedGetHours.bind(this))
    }

    /**
     * Debounced version of the getHours method. This is used to prevent the
     * getHours method from being called multiple times when the user is changing dates
     * @type {function(...[*]): void}
     */
    debouncedGetHours = debounce(this.getHours.bind(this), 300);

    /**
     * Fetches the estimated leave request hours from the server based on the
     * start and end dates provided by the user.
     */
    getHours() {

        // Wait for both values to be populated
        if (!this.start_dateTarget.value || !this.end_dateTarget.value) {
            return
        }

        getRequest(`/api/v2/leave_requests/estimate_hours?start_date=${this.start_dateTarget.value}&end_date=${this.end_dateTarget.value}`).then(response => {
            this.hoursTarget.value = response.hours;
        }).catch(error => {
            // Log the error
            console.error(error)
            // Don't do anything, let the user manually enter the hours
        })
    }
}