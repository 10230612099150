import { Controller } from "stimulus";
import { getRequest } from "../helpers/api";
import { addValueToArrayInput, removeValueFromArrayInput } from "../helpers/form_helpers";

const MIN_SEARCH_LENGTH = 3;

export default class extends Controller {
    static targets = ["searchContainer", "searchInput", "resultsContainer", "selectedMembers", "container", "selectedContainer", "excludedMembers"];
    timeout = null;

    connect() {
        this.searchContainerTarget.addEventListener("focusout", this.hideResults.bind(this));
        this.searchContainerTarget.addEventListener("focusin", this.handleFocusIn.bind(this));
        this.searchContainerTarget.addEventListener("click", this.showResults.bind(this));
        this.searchInputTarget.addEventListener("input", this.handleInput.bind(this));
    }

    handleFocusIn() {
        if (this.searchInputTarget.value.length > 2) {
            this.showResults();
        } else {
            this.hideResults();
        }
    }

    handleInput() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.searchMembers.bind(this), 500);
    }

    searchMembers() {
        const searchValue = this.searchInputTarget.value;
        if (searchValue.length === 0) {
            this.resultsContainerTarget.innerHTML = "";
            this.hideResults();
        } else if (searchValue.length >= MIN_SEARCH_LENGTH) {
            getRequest(`/api/v2/members/search?full_name=${encodeURIComponent(searchValue)}&active_funding_plan=true`).then(data => {
                this.resultsContainerTarget.innerHTML = "";
                this.showResults();
                data = this.filterExistingUsersFromSearch(data);
                if (data.length === 0) {
                    this.showNoResults();
                } else {
                    data.forEach(this.addSearchResults.bind(this));
                }
            });
        }
    }

    selectMemberFromSearch(event) {
        const { memberName: name, memberId: id } = event.target.dataset;
        this.addMemberToSelected(name, id);
        addValueToArrayInput(this.selectedMembersTarget, id);
        this.searchInputTarget.value = "";
        this.resultsContainerTarget.innerHTML = "";
        this.hideResults();
        this.searchInputTarget.focus();
    }

    addMemberToSelected(name, id) {
        const memberPill = document.createElement("div");
        memberPill.classList.add("bg-gray-100", "text-gray-800", "me-2", "inline-flex", "items-center", "ps-3", "pe-2", "py-0.5", "rounded-full", "text-sm", "font-medium");
        memberPill.innerHTML = name;
        const removeButton = document.createElement("button");
        removeButton.classList.add("ml-2", "text-gray-400", "hover:text-gray-700", "focus:outline-none");
        removeButton.innerHTML = '<i class="fa fa-times-circle"></i>';
        removeButton.dataset.memberId = id;
        removeButton.setAttribute("data-action", "click->member-search-and-select#removeMemberFromSelected");
        memberPill.append(removeButton);
        this.selectedContainerTarget.append(memberPill);
    }

    removeMemberFromSelected(event) {
        const id = event.target.closest("button").dataset["memberId"];
        removeValueFromArrayInput(this.selectedMembersTarget, id);
        event.target.closest("div").remove();
    }

    filterExistingUsersFromSearch(data) {
        let filteredData = data.filter(m => !this.selectedMembersTarget.value.includes(m.id));
        if (this.excludedMembersTarget.value.length > 0) {
            const excludedMembers = this.excludedMembersTarget.value.split(",").map(m => parseInt(m));
            filteredData = filteredData.filter(m => !excludedMembers.includes(m.id));
        }
        return filteredData;
    }

    showNoResults() {
        const noResultsElement = document.createElement("div");
        noResultsElement.classList.add("p-3");
        noResultsElement.innerHTML = "No members found. Please try a different search term.";
        this.resultsContainerTarget.appendChild(noResultsElement);
    }

    addSearchResults(member) {
        const memberElement = document.createElement("div");
        memberElement.classList.add("member", "p-3", "hover:bg-ascendPurple", "focus:bg-ascendPurple", "cursor-pointer");
        memberElement.setAttribute("data-member-id", member.id);
        memberElement.setAttribute("data-member-name", member.full_name);
        memberElement.id = `member-pill-${member.id}`;
        memberElement.setAttribute("data-action", "click->member-search-and-select#selectMemberFromSearch");
        memberElement.innerHTML = member.full_name;
        this.resultsContainerTarget.appendChild(memberElement);
    }

    hideResults() {
        this.resultsContainerTarget.classList.add("hidden");
    }

    showResults() {
        this.resultsContainerTarget.classList.remove("hidden");
    }

    toggleSearchVisibility(event) {
        if (event.target.checked) {
            this.containerTarget.classList.remove("hidden");
        } else {
            this.containerTarget.classList.add("hidden");
            this.searchInputTarget.value = "";
            this.resultsContainerTarget.innerHTML = "";
            this.selectedContainerTarget.innerHTML = "";
            this.selectedMembersTarget.value = "";
        }
    }
}