import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]

  // This controller is used to switch between edit and display mode for a text field.
  connect() {
    this.hideInput()
    this.updateButtonText()
  }

  hideInput() {
    this.inputTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
  }

  showInput() {
    this.inputTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
  }

  updateButtonText() {
    this.buttonTarget.innerText = this.inputTarget.value
  }
}