/**
 * Fetch the central location from the URL and return it as a JSON object
 */
export const getMapParams = () => {
    const url = new URL(window.location.href);
    const latitude = url.searchParams.get("latitude");
    const longitude = url.searchParams.get("longitude");
    const title = url.searchParams.get("title");

    return {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        title
    };
}
