import {Controller} from "stimulus";
import {getMetaValue} from "../helpers";

export default class extends Controller {

    static targets = ["template", "container", "role"];

    colors = [
        "bg-red-400",
        "bg-orange-500",
        "bg-green-400",
        "bg-purple-400"
    ]

    connect() {
        this.url = this.element.dataset.metricsUrl;
        this.fetchData();
    }

    async fetchData() {
        const request = await fetch(this.url, {
            method: 'GET',
            credentials: 'include',
            dataType: 'script',
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token"),
                "Content-Type": "application/json"
            },
        }).catch(error => console.error('Error:', error));

        const data = await request.json().catch(error => console.error('Error:', error));

        // Build the career metrics list

        const careerMetrics = data.employment_metrics ?? [];
        this.roleTarget.textContent = data.provider_role ?? "";
        if (careerMetrics.length > 0) {
            this.renderCareerMetrics(careerMetrics);
        } else {
            this.renderNoContent();
        }

    }


    renderNoContent() {
        const noContent = document.createElement("div");
        noContent.textContent = "No career metrics available";
        noContent.classList.add("text-md");
        this.containerTarget.innerHTML = "";
        this.containerTarget.appendChild(noContent);
    }

    renderCareerMetrics(careerMetrics) {

        // Fetch the template
        const template = this.templateTarget;

        // Clear the container
        this.containerTarget.innerHTML = "";

        // Render no content if there are no metrics
        if (careerMetrics.length === 0) {
            const noContent = document.createElement("div");
            noContent.textContent = "No career metrics available";
            noContent.classList.add("text-lg");
            this.containerTarget.appendChild(noContent);
            return;
        }

        careerMetrics.forEach((metric, index) => {
            // Create a copy of the template
            const clone = template.content.firstElementChild.cloneNode(true);

            // Replace the values in the target
            clone.querySelector('[data-label]').textContent = metric.name;
            clone.querySelector('[data-level-req]').textContent = metric.this_level_req;
            clone.querySelector('[data-level]').textContent = metric.this_level;

            // Clamp the percentage to 100
            let percentage = metric.this_level / metric.this_level_req * 100;
            if (percentage > 100) percentage = 100;
            if (percentage < 0) percentage = 0;

            clone.querySelector('[data-level-percentage]').textContent = percentage.toFixed(1) + "%";

            const progressBar = clone.querySelector('[data-progress-bar]');
            progressBar.style.width = percentage + "%";
            progressBar.classList.add(this.colors[index % this.colors.length]);

            // Append to the container
            this.containerTarget.appendChild(clone);
        })

    }


}
