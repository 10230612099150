// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB6dPO3uLway4yQsPaXbLi49kXwkAkE10M",
    authDomain: "ascend-health-plus-helper.firebaseapp.com",
    projectId: "ascend-health-plus-helper",
    storageBucket: "ascend-health-plus-helper.appspot.com",
    messagingSenderId: "990920715088",
    appId: "1:990920715088:web:30d3fcacd9598479871a55",
    measurementId: "G-Q973ESB9YD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const logFirebaseEvent = (eventName, eventParams) => {
    eventParams = {
        ...eventParams,
        environment: process.env.RAILS_ENV
    }

    logEvent(analytics, eventName, eventParams)
}